import React from "react";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import "./header.scss";
import ascentLogo from "../../img/ascent_logo.png";
import logoutIcon from "../../img/logout.png";
import profileIcon from "../../img/ffprofile.png";
import notificationsIcon from "../../img/notifications.png";
import { Link, useNavigate } from "react-router-dom";

export default function Header({ loggedIn }) {

  const navigate = useNavigate();

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.clear();
    } catch (err) {
      console.error(err);
    }
  };

  function logoClick() {
    navigate("/")
  }

  return (
    <header className="header">
      <div className="header-inner-container">
        <img src={ascentLogo} alt="Company Logo" className="logo" onClick={logoClick} />
        <h1 className="company-name" onClick={logoClick}>
          Ascent Chief Portal
        </h1>
      </div>
      {loggedIn ? (
        <div className="header-inner-container header-icons">
          <Link to={`/profile`} className="profile-link">
            <img src={profileIcon} alt="Profile icon" className="profile"></img>
          </Link>
          <Link to={`/reports`} className="profile-link">
            <img src={notificationsIcon} alt="Notifications icon" className="notifications"></img>
          </Link>
          <img src={logoutIcon} alt="Logout icon" className="logout" onClick={logout}></img>
        </div>
      ) : null}
    </header>
  );
}
