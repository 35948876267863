import React, { useState } from "react";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";
import Axios from "axios";
import "./profile.scss";

export default function Profile({ admin, user, config, name }) {
  const [resetPasswordText, setResetPasswordText] = useState(null);

  const deleteProfile = async () => {
    Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/chief/delete`,
      {
        uuid: user.uid,
      },
      config
    )
      .then(async (response) => {
        console.log("Delete profile", response.data);

        const user = auth.currentUser;

        if (user) {
          try {
            await user.delete();
            console.log("Firebase user deleted");
          } catch (firebaseError) {
            console.error("Error deleting Firebase user:", firebaseError);
          }
        }
      })
      .catch((error) => {
        console.log("deleteProfileError", error);
      });
  };

  const resetPassword = () => {
    sendPasswordResetEmail(auth, user.email)
      .then(() => {
        console.log("Reset email sent");
        setResetPasswordText("An email to reset your password has been sent.");
      })
      .catch((error) => {
        console.log("Reset email error", error);
        setResetPasswordText(error.message);
      });
  };

  return (
    <>
      <div className="profile-page">
        <h2 className="profile-header">Profile Information</h2>
        <div className="profile-container">
          {resetPasswordText ? <span className="profile-reset-password-text">{resetPasswordText}</span> : null}
          <div className="profile-user-info-container">
            <div className="profile-user-info">
              <span className="profile-info-header">Display Name: </span>
              <span className="profile-user-text">{user.displayName ? user.displayName : name}</span>
            </div>
            <div className="profile-user-info">
              <span className="profile-info-header">Email: </span>
              <span className="profile-user-text">{user.email}</span>
            </div>
          </div>
          <div className="profile-user-info-container">
            <div className="profile-user-info">
              <span className="profile-info-header">Reset Password: </span>
              <div className="delete-profile-button reset-password-button">
                <div
                  className="delete-profile-text"
                  onClick={() => {
                    resetPassword();
                  }}
                >
                  Reset Password
                </div>
              </div>
            </div>
            <div className="profile-user-info">
              <span className="profile-info-header">Delete Account: </span>
              <div className="delete-profile-button">
                <div
                  className="delete-profile-text"
                  onClick={() => {
                    deleteProfile();
                  }}
                >
                  Delete Profile
                </div>
              </div>
            </div>
          </div>
          {admin && (
            <Link to="/register" className="to-register">
              Register a New Account
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
