import React, { useContext } from "react";
import { Link } from "react-router-dom";
import whiteLogo from "../../img/ascent_logo_white.png";
import "./home.scss";
import { DeviceContext, SessionsContext, FFContext } from "../../Contexts.js";

export default function Home({ name }) {
  console.log("server URL:", process.env.REACT_APP_DB_SERVER_URL);

  const { devices } = useContext(DeviceContext);
  const { sessions } = useContext(SessionsContext);
  const { firefighters } = useContext(FFContext);

  let options = { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };

  return (
    <div>
      <div className="home-container">
        <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img>
        <div className="home-title">Welcome{name ? `, ${name}` : ""}!</div>
        <div className="cards-container">
          <div className="card-title-container">
            <div className="card-title">Devices</div>
            <div className="card">
              {devices && devices.length > 0 ? (
                <>
                  {
                    // map the user's devices
                    devices.map((item, index) => (
                      <Link to={`/device/${item.device_id}/${item.device_name ? item.device_name : item.device_id}`} className="inner-card" key={index}>
                        <div className="inner-card-content">
                          <div className="inner-title">{item.device_name ? item.device_name : item.device_id}</div>
                        </div>
                      </Link>
                    ))
                  }
                </>
              ) : (
                <div className="inner-card">
                  <div className="inner-card-content">
                    <div className="inner-title">You have no devices!</div>
                  </div>
                </div>
              )}
            </div>
            <Link className="home-button" to="/deviceManager">
              Manage Devices
            </Link>
          </div>
          <div className="card-title-container">
            <div className="card-title">Firefighters</div>
            <div className="card">
              {firefighters && firefighters.length > 0 ? (
                // map the user's firefighters
                firefighters.map((item, index) => (
                  <Link to={`/firefighter/${item.uuid}`} className="inner-card" key={index}>
                    <div className="inner-card-content-ff">
                      {/* <img className="ff-home-profile" src={`data:image/jpeg;base64,${item.profile}`} alt="Profile"></img> */}
                      <div className="inner-title">{item.name}</div>
                    </div>
                  </Link>
                ))
              ) : (
                <div className="inner-card">
                  <div className="inner-card-content">
                    <div className="inner-title">You have no firefighters!</div>
                  </div>
                </div>
              )}
            </div>
            <Link className="home-button" to="/firefighterManager">
              Manage Firefighters
            </Link>
          </div>
          <div className="card-title-container">
            <div className="card-title">Sessions</div>
            <div className="card">
              {sessions && sessions.length > 0 ? (
                // map the user's sessions
                sessions.map((item, index) => (
                  <Link to={`/session/${item.session_id}/${new Date(item.datetime).toLocaleString("en-US", options)}`} className="inner-card" key={index}>
                    <div className="inner-card-content">
                      <div className="inner-title">{new Date(item.datetime).toLocaleString("en-US", options)}</div>
                    </div>
                  </Link>
                ))
              ) : (
                <div className="inner-card">
                  <div className="inner-card-content">
                    <div className="inner-title">You have no sessions!</div>
                  </div>
                </div>
              )}
            </div>
            <Link className="home-button" to="/sessions">
              View All Sessions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
