import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/home/home";
import DeviceManager from "./components/deviceManager/deviceManager";
import Device from "./components/device/device";
import Session from "./components/session/session";
import SessionSearch from "./components/sessionSearch/sessionSearch";
import FirefighterManager from "./components/firefighterManager/firefighterManager";
import Firefighter from "./components/firefighter/firefighter";
import Profile from "./components/profile/profile";
import Login from "./components/login/login";
import Register from "./components/register/register";
import Reports from "./components/reports/reports";
import ReportCard from "./components/reports/reportCard";
import { auth } from "./firebase";
import Axios from "axios";
import { useAuth } from "./AuthContext.js";
import { updateProfile } from "firebase/auth";
import NotFound from "./components/notFound/notFound";
import Header from "./components/header/header";
import { DeviceProvider, SessionsProvider, SessionProvider, FFProvider, TabletsProvider, FFProfilesProvider } from "./Contexts.js";
import { BrowserRouter } from "react-router-dom";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [dept, setDept] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(undefined);

  const { idToken } = useAuth();

  let config = {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  };

  const fetchFavorites = async (startDate, endDate, startTime, endTime, address, minLength, maxLength, deptSearch) => {
    return Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/favorites/fetch/${user.uid}`,
      {
        chief_uuid: user.uid,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        address: address,
        min_length: minLength,
        max_length: maxLength,
        department: deptSearch,
      },
      config
    )
      .then((response) => {
        return response.data.favorites.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateFavorites = async (session_id) => {
    return Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/favorites/update/${user.uid}`,
      {
        session_id: session_id,
      },
      config
    ).catch((error) => {
      console.log(error);
    });
  };

  const getFirstName = async () => {
    if (idToken && auth.currentUser && !auth.currentUser.displayName) {
      Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/chief/fetch/${auth.currentUser.uid}`, config)
        .then((response) => {
          setName(response.data.name.split(" ")[0]);
          updateProfile(auth.currentUser, { displayName: response.data.name.split(" ")[0] });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const inputs = Array.from(document.querySelectorAll("input"));
      const currentIndex = inputs.findIndex((input) => input === document.activeElement);
      if (currentIndex >= 0 && currentIndex < inputs.length - 1) {
        inputs[currentIndex + 1].focus();
      } else {
        let firstNonCheckboxInput = document.querySelector("input:not([type='checkbox'])");
        firstNonCheckboxInput.focus();
      }
    }
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      console.log("user dept", dept);
      console.log("is user an admin?", admin);
    }
  }, [user, admin, dept]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            const claims = idTokenResult.claims;
            claims.Department === "Admin" ? setAdmin(true) : setAdmin(false);
            setDept(claims.Department === "Admin" ? "Ascent" : claims.Department);
          })
          .catch((error) => {
            console.log("Custom claims error", error);
          });
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      getFirstName();
    }
    // eslint-disable-next-line
  }, [user]);

  if (loading) {
    return (
      <div className="report-loading-container">
        <i alt="Loading icon" className="fa-solid fa-fire-extinguisher fa-beat fire-extinguisher-icon"></i>
        <div>Loading ...</div>
      </div>
    );
  }

  return (
    <>
      <FFProvider config={config} admin={admin} dept={dept}>
        <DeviceProvider config={config} admin={admin} dept={dept}>
          <TabletsProvider config={config} dept={dept}>
            <FFProfilesProvider config={config}>
              <SessionsProvider user={user} config={config} admin={admin} dept={dept}>
                <BrowserRouter>
                  <Header loggedIn={isAuthenticated} />
                  <Routes>
                    <Route path="/" element={isAuthenticated ? <Home name={user.displayName} /> : <Navigate to="/login" />} />
                    {admin && <Route path="/deviceManager/:tabletParam?" element={isAuthenticated ? <DeviceManager dept={dept} config={config} /> : <Navigate to="/login" />} />}
                    {admin && <Route path="/device/:deviceID?/:deviceName?" element={isAuthenticated ? <Device config={config} /> : <Navigate to="/login" />} />}
                    <Route
                      path="/session/:sessionParam?/:sessionDatetime?"
                      element={
                        isAuthenticated ? (
                          <SessionProvider config={config}>
                            <Session config={config} />
                          </SessionProvider>
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    />
                    <Route path="/sessions" element={isAuthenticated ? <SessionSearch updateFavorites={updateFavorites} fetchFavorites={fetchFavorites} handleKeyPress={handleKeyPress} admin={admin} config={config} dept={dept} user={user} /> : <Navigate to="/login" />} />
                    {admin && <Route path="/firefighterManager" element={isAuthenticated ? <FirefighterManager user={user} dept={dept} config={config} /> : <Navigate to="/login" />} />}
                    {admin && <Route path="/firefighter/:uuid?" element={isAuthenticated ? <Firefighter config={config} /> : <Navigate to="/login" />} />}
                    <Route path="/profile" element={isAuthenticated ? <Profile config={config} admin={admin} user={user} name={name} /> : <Navigate to="/login" />} />
                    <Route path="/login" element={<Login handleKeyPress={handleKeyPress} />} />
                    <Route path="/register" element={admin ? <Register handleKeyPress={handleKeyPress} config={config} /> : <Navigate to="/profile" />} />
                    <Route path="/reports" element={isAuthenticated ? <Reports user={user} config={config} /> : <Navigate to="/login" />} />
                    <Route path="/report" element={isAuthenticated ? <ReportCard dept={dept} config={config} /> : <Navigate to="/login" />} />
                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                </BrowserRouter>
              </SessionsProvider>
            </FFProfilesProvider>
          </TabletsProvider>
        </DeviceProvider>
      </FFProvider>
    </>
  );
}

export default App;
