import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./notFound.scss";

export default function NotFound() {
  const navigate = useNavigate(); 

  useEffect(() => {
    navigate("/not-found", { replace: false }); 
  }, [navigate]); 

  return (
    <div>
      <div className="page-not-found">
        <h2>Page Not Found</h2>
        <Link to="/" className="to-login">
          Back to Home
        </Link>
      </div>
    </div>
  );
}
