import React, { useEffect, useRef } from "react";
import Plotly from "plotly.js-dist";
import "./plot.scss";

export default function Plot(props) {
  const plotRef = useRef(null);
  const ticks = window.innerWidth < 900 ? 10 : 20;
  const rightMargin = window.innerWidth < 550 ? 70 : 80;
  const trackingStateMap = {
    0: "Normal",
    1: "Excessive<br>Motion",
    2: "Insufficient",
    3: "Initializing",
    4: "Relocalizing",
    5: "N/A",
    6: "N/A",
  };
  const thermalStateMap = {
    0: "Low",
    1: "Fair",
    2: "Serious",
    3: "Critical",
  };

  function roundToNearestHalfSecond(num) {
    // if (num > 0 && num < 0.5) {
    //   return 0.5;
    // }
    // return Math.round(num * 2) / 2;
    return num
  }

  const yData1 = props.yData1 && props.yData1.length ? props.yData1.map((value) => thermalStateMap[value]) : undefined;
  const yData2 = props.yData2 && props.yData2.length ? props.yData2.map((value) => roundToNearestHalfSecond(value)) : undefined;
  const yData3 = props.yData3 && props.yData3.length ? props.yData3.map((value) => trackingStateMap[value]) : undefined;
  const yData4 = props.yData4 && props.yData4.length ? props.yData4 : undefined;

  const xRange = props.zData ? Math.max(...props.xData) - Math.min(...props.xData) : null;
  const yRange = props.zData ? Math.max(...props.yData) - Math.min(...props.yData) : null;
  const zRange = props.zData ? Math.max(...props.zData) - Math.min(...props.zData) : null;

  useEffect(() => {
    const currentPlotRef = plotRef.current;

    const colorMap = {
      Normal: "blue",
      Insufficient: "red",
      "Excessive<br>Motion": "green",
      Initializing: "grey",
      Relocalizing: "orange",
      "N/A": "black",
    };

    const times = props.times
      ? props.times.map((time) => {
          let date = new Date(time * 1000);
          let hours = date.getHours();
          let minutes = "0" + date.getMinutes();
          let seconds = "0" + date.getSeconds();

          // Will display time in HH:MM:SS format
          let formattedTime = hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
          return formattedTime;
        })
      : null;

    // regular x y data for thermalStateData
    const scatterPlotTrace1 = {
      x: times,
      y: yData1 ? yData1 : [], // thermalStateData
      customdata: times,
      type: "scatter",
      mode: props.mode,
      name: "Thermal Status",
      yaxis: "y",
      line: { color: "#FFAC1C", width: 3 },
    };

    // regular x y data for lastUpdateData
    const scatterPlotTrace2 = {
      x: times,
      y: yData2 ? yData2 : [],
      customdata: times,
      type: "scatter",
      mode: props.mode,
      name: "Secs. Since Last Refresh",
      yaxis: "y2",
      line: { color: "#0096FF" },
    };

    // regular x y data for trackingStateData
    const scatterPlotTrace3 = {
      x: times,
      y: yData3 ? yData3 : [], // trackingStateData
      customdata: times,
      type: "scatter",
      mode: props.mode,
      name: "Tracking Status",
      yaxis: "y3",
    };

    // regular x y data for batteryStateData
    const scatterPlotTrace4 = {
      x: times,
      y: yData4 ? yData4 : [], // batteryStateData
      customdata: times,
      type: "scatter",
      mode: props.mode,
      name: "Battery Status",
      yaxis: "y4",
    };

    // 3D XYZ odometry
    const scatterPlotTrace3D = props.zData
      ? {
          x: props.xData,
          y: props.yData,
          z: props.zData,
          text: props.trackingStates.map((state) => {
            return trackingStateMap[state];
          }),
          // text: props.trackingStates,
          customdata: times,
          type: "scatter3d",
          mode: props.mode,
          marker: {
            size: 4,
            // color markers depending on tracking state
            color: props.trackingStates.map((state) => {
              return colorMap[trackingStateMap[state]];
            }),
          },
          line: {
            size: 4,
            // color markers depending on tracking state
            color: props.trackingStates.map((state) => {
              return colorMap[trackingStateMap[state]];
            }),
          },
          hovertemplate: "X: %{x}<br>Y: %{y}<br>Z: %{z}<br>Tracking State: %{text}<br>Time: %{customdata}<br>",
          xaxis: "x",
          yaxis: "y",
          zaxis: "z",
          name: "XYZ Coordinates",
        }
      : null;

    // Create the data array
    const data = scatterPlotTrace3D ? [scatterPlotTrace3D] : [scatterPlotTrace1, scatterPlotTrace2, scatterPlotTrace3, scatterPlotTrace4];

    // Set the layout options
    const layout = {
      title: {
        text: props.title,
      },
      height: 800,
      margin: {
        r: rightMargin,
        l: 35,
        t: 70,
      },
      annotations: [
        {
          text: props.yTitle1,
          showarrow: false,
          align: "left",
          xref: "paper",
          yref: "paper",
          y: 0.96,
          x: 0,
          xshift: "-25",
          textangle: -90,
          font: {
            size: 14,
          },
        },
        {
          text: props.yTitle2,
          showarrow: false,
          align: "center",
          xref: "paper",
          yref: "paper",
          x: 0,
          xshift: "-25",
          y: 0.64,
          textangle: -90,
          font: {
            size: 14,
          },
        },
        {
          text: props.yTitle3,
          showarrow: false,
          align: "right",
          xref: "paper",
          yref: "paper",
          x: 0,
          xshift: "-25",
          y: 0.29,
          textangle: -90,
          font: {
            size: 14,
          },
        },
        {
          text: props.yTitle4,
          showarrow: false,
          align: "right",
          xref: "paper",
          yref: "paper",
          x: 0,
          xshift: "-25",
          y: 0.04,
          textangle: -90,
          font: {
            size: 14,
          },
        },
      ],
      xaxis: {
        domain: [0, 1],
        anchor: "y4",
        title: props.xTitle,
        tickangle: -60,
        nticks: ticks,
      },
      yaxis: {
        domain: [0.78, 1],
        range: [0, 4],
        anchor: "x",
        showticklabels: true,
        mirror: "ticks",
        side: "right",
        showline: false,
        zeroline: true,
        categoryarray: ["Low", "Fair", "Serious", "Critical"],
      },
      yaxis2: {
        domain: [0.52, 0.74],
        range: yData2 && yData2.length ? [0, Math.max(...yData2) + 0.5] : [0, 5],
        anchor: "x",
        showticklabels: true,
        mirror: "ticks",
        side: "right",
        showline: false,
        zeroline: false,
      },
      yaxis3: {
        domain: [0.26, 0.48],
        anchor: "x",
        type: "category",
        range: [0, 5],
        categoryarray: ["Initializing", "N/A", "Insufficient", "Normal", "Excessive<br>Motion"],
        showticklabels: true,
        side: "right",
        showline: false,
        zeroline: true,
      },
      yaxis4: {
        domain: [0, 0.22],
        range: yData4 && yData4.length ? [Math.min(...yData4) - 1, Math.max(...yData4) + 1] : [0, 100],
        anchor: "x",
        showticklabels: true,
        mirror: "ticks",
        side: "right",
        showline: false,
        zeroline: false,
      },
      hovermode: "closest",
      showlegend: false,
      responsive: true,
    };

    // 3d layout options
    const layout3D = props.zData
      ? {
          title: {
            text: props.title,
          },
          margin: {
            t: 35,
          },
          height: 600,
          scene: {
            xaxis: {
              title: props.xTitle,
            },
            yaxis: {
              title: props.yTitle,
            },
            zaxis: {
              title: props.zTitle,
            },
            aspectmode: "manual",
            aspectratio: {
              x: xRange / Math.max(xRange, yRange, zRange),
              y: yRange / Math.max(xRange, yRange, zRange),
              z: zRange / Math.max(xRange, yRange, zRange),
            },
            camera: {
              eye: { x: 1, y: 1, z: 0.5 },
              center: { x: 0, y: 0, z: 0 },
              up: { x: 0, y: 0, z: 1.0 },
            },
          },
          hovermode: "closest",
          showlegend: false,
          responsive: true,
        }
      : null;

    // Create the plot
    // Plotly.newPlot(props.plotID, data, layout3D ? layout3D : layout);
    Plotly.newPlot(props.plotID, data, layout3D ? layout3D : layout);

    // Clean up the plot when the component is unmounted
    return () => {
      if (currentPlotRef) {
        Plotly.purge(currentPlotRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.title, props.times, props.xData, props.yData, props.zData, props.xTitle, props.yTitle, props.zTitle, props.plotID, props.mode, props.trackingStates, props.yData1, props.yData2, props.yData3, props.yTitle1, props.yTitle2, props.yTitle3]);

  return <div className="plot-container-ref" ref={plotRef} id={props.plotID} />;
}
