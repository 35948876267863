import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Polyline, Tooltip, CircleMarker, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./plot.scss";
import star from "../../img/star_filled.png";
import L from "leaflet";

export default function GPSPlot(props) {
  const mapRef = useRef(null);

  let gpsData = props.gpsData
    ? Object.entries(props.gpsData)
        .filter(([key, value]) => value["calibrated_lats"] && value["calibrated_lats"].length > 0)
        .map(([key, value]) => ({ value }))
    : null;

  const calibrated = gpsData ? gpsData.length > 0 : props.calibratedLats && props.calibratedLats.length > 0;

  const colors = ["yellow", "red", "green", "blue", "orange", "purple", "pink", "teal", "white", "black", "grey", "lightblue"];

  const starIcon = L.icon({
    iconUrl: star,
    iconSize: [25, 25],
    iconAnchor: [12.5, 12.5],
  });

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      if (props.latData && props.latData.length > 0) {
        map.flyTo([props.latData[0], props.longData[0]], 19);
      }
    }
  }, [props.latData, props.longData]);

  return (
    <>
      {calibrated ? (
        <div className="gps-plot-container-ref" key={props.index}>
          {props.firefighterPlot ? (
            <div className="plot-title-ff">{props.title}</div>
          ) : (
            <>
              <div className={props.title ? "gps-title-and-address-container" : ""}>
                <div className="plot-title-gps">{props.title}</div>
              </div>
            </>
          )}
          <MapContainer
            center={gpsData ? [gpsData[0]["value"]["calibrated_lats"][0], gpsData[0]["value"]["calibrated_longs"][0]] : [props.latData[0], props.longData[0]]}
            maxZoom={22}
            zoom={19}
            style={{ height: "600px" }}
            ref={mapRef}
            preferCanvas={true}
          >
            <TileLayer 
              // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' 
              attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
              // url="https://{s}.tile.jawg.io/jawg-matrix/{z}/{x}/{y}{r}.png?access-token={accessToken}" 
              url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token={accessToken}"
              maxNativeZoom={19} 
              minZoom={0} 
              maxZoom={22} 
              accessToken="pk.eyJ1IjoibWF4c2F1ZXJzMTMiLCJhIjoiY2xqc3llaDJoMHF6dDNrcnd1bXA2cmY2cyJ9.KoX35LClG0lNdZnkcyGoNw"
            />
            {props.tabletGPS &&
              <Marker
                position={props.tabletGPS}
                icon={starIcon}
              >
                <Tooltip>Tablet GPS: {props.tabletGPS[0]}, {props.tabletGPS[1]}</Tooltip>
              </Marker>
            }
            {gpsData ? (
              <>
                {gpsData.map(
                  (item, i) =>
                    item["value"]["calibrated_lats"].length > 0 && (
                      <React.Fragment key={i}>
                        <Polyline key={i} positions={item["value"]["calibrated_lats"].map((lat, j) => [lat, item["value"]["calibrated_longs"][j]])} color={colors[i % 12]}>
                          <Tooltip>{item["value"]["uuid"]}</Tooltip>
                        </Polyline>

                        {item["value"]["calibrated_lats"].map((lat, j) => (
                          <CircleMarker key={`${lat}-${j}`} center={[lat, item["value"]["calibrated_longs"][j]]} radius={2} fillColor={colors[i % 12]} color={colors[i % 12]}></CircleMarker>
                        ))}
                      </React.Fragment>
                    )
                )}
                <div className="map-legend">
                  {gpsData.map((item, i) => (
                    <div key={i} className="legend-device-container">
                      <div className={`legend-icon`} style={{ backgroundColor: colors[i % 12] }}></div>
                      <div className="legend-device-info-container">
                        <div className="legend-device-title">{item["value"]["uuid"]}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {!props.firefighterPlot && props.latData.length > 0 && (
                  <>
                    <Polyline positions={props.latData.map((lat, i) => [lat, props.longData[i]])} color="red" />
                    {props.latData.map((lat, i) => (
                      <CircleMarker key={`${lat}-${i}`} center={[lat, props.longData[i]]} radius={2} fillColor="red" color="red">
                        <Tooltip>Uncertainty: {props.uncertainty[i] ? parseFloat(props.uncertainty[i].toFixed(2)) : props.uncertainty[i]}</Tooltip>
                      </CircleMarker>
                    ))}
                  </>
                )}
                {props.calibratedLats && props.calibratedLats.length > 0 && <Polyline positions={props.calibratedLats.map((lat, i) => [lat, props.calibratedLongs[i]])} color="lightgreen" />}
                <div className="map-legend">
                  <div className="legend-device-container">
                    <div className="legend-icon legend-icon-green"></div>
                    <div className="legend-device-info-container">
                      <div className="legend-device-title">Calibrated Coordinates</div>
                    </div>
                  </div>
                  {!props.firefighterPlot && (
                    <div className="legend-device-container">
                      <div className=" legend-icon legend-icon-red"></div>
                      <div className="legend-device-info-container">
                        <div className="legend-device-title">GPS Coordinates</div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </MapContainer>
        </div>
      ) : gpsData ? (
        <div className="session-data-error">Devices not calibrated.</div>
      ) : null}
    </>
  );
}
