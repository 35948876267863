import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import backarrow from "../../img/backarrow.png";
import Axios from "axios";
import ReportPlot from "../plot/reportPlots.js";
import ffprofile from "../../img/ffprofile.png";
import ffIcon from "../../img/firefighter.png";
import locationIcon from "../../img/black-location.png";
import clockIcon from "../../img/black-clock.png";
import fireIcon from "../../img/fire-extinguisher.png";
import emailIcon from "../../img/email.png";
import close from "../../img/black-x.png";

export default function ReportCard({ dept, config }) {
  const { state } = useLocation();
  const [loading, setLoading] = useState(undefined);
  const [addresses, setAddresses] = useState(undefined);
  const [averageLength, setAverageLength] = useState(undefined);
  const [firefighters, setFirefighters] = useState(undefined);
  const [longestSession, setLongestSession] = useState(undefined);
  const [times, setTimes] = useState(undefined);
  const [totalLength, setTotalLength] = useState(undefined);
  const [ffsMostSessions, setFfsMostSessions] = useState(undefined);
  const [sessionCount, setSessionCount] = useState(undefined);
  const [ffImages, setFfImages] = useState([]);
  const [toggleEmailInput, setToggleEmailInput] = useState(false);

  const timeOfDay = (time) => {
    if (time === 0) {
      return "12AM";
    }
    if (time === 12) {
      return "12PM";
    } else if (time <= 11) {
      return time + "AM";
    } else {
      return time - 12 + "PM";
    }
  };

  const getFFPictures = (UUIDs) => {
    Axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/firefighters/fetch/profiles`, UUIDs, config)
      .then((response) => {
        setFfImages(response.data.images);
      })
      .catch((error) => {
        console.log("ff image error:", error.message);
      });
  };

  useEffect(() => {
    if (firefighters && firefighters.length >= 3) {
      let UUIDs = [firefighters[0].uuid, firefighters[1].uuid, firefighters[2].uuid, ffsMostSessions[0].uuid, ffsMostSessions[1].uuid, ffsMostSessions[2].uuid];
      UUIDs = UUIDs.reduce((accum, uuid) => {
        if (!accum.firefighters) {
          accum.firefighters = [];
        }
        accum.firefighters.push({ uuid: uuid });
        return accum;
      }, {});
      getFFPictures(UUIDs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firefighters, ffsMostSessions]);

  useEffect(() => {
    setLoading(true);
    let dates = state.dates.replaceAll("/", "-").split(" ");
    Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/report/fetch/${dept}/${dates[0]}/${dates[2]}`, config)
      .then((response) => {
        console.log("dept stats", response.data);
        setSessionCount(response.data.addresses.length);
        setAddresses(countAddressDuplicates(response.data.addresses));
        setAverageLength(response.data["average_length"]);
        let filteredFirefighters = response.data.firefighters.filter((ff) => ff["total_length"]);
        filteredFirefighters = filteredFirefighters.filter((ff) => (ff.name.includes(" ") ? (ff.name = ff.name.slice(0, ff.name.indexOf(" ") + 2)) : ff.name));
        setFirefighters(filteredFirefighters.sort((a, b) => b["total_length"] - a["total_length"]));
        setFfsMostSessions(response.data.firefighters.sort((a, b) => b["total_sessions"] - a["total_sessions"]));
        setLongestSession(response.data["longest_session"]);
        setTimes(response.data.times);
        setTotalLength(response.data["total_length"]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [state.dates, dept, config]);

  const countAddressDuplicates = (array) => {
    const counts = {};
    array = array.filter((item) => item);
    array.forEach((str) => {
      counts[str] = (counts[str] || 0) + 1;
    });

    return Object.keys(counts).map((key) => {
      return { string: key, count: counts[key] };
    });
  };

  const convertSecondsToHHMM = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return hours ? `${hours}h ${formattedMinutes}m` : `${minutes}m`;
  };

  const sendEmail = () => {
    let email = document.getElementById("email");
    let dates = state.dates.replaceAll("/", "-").split(" ");
    let data = { receiver_email: email.value, department: dept, email_preference: (state.period === "YTD" ? "Lifetime" : state.period), start_date: dates[0], end_date: dates[2] };
    setToggleEmailInput(false);

    Axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/report/send/${dept}`, data, config)
      .then((response) => {
        console.log("sent email", response);
      })
      .catch((error) => {
        console.log("email report error:", error.message);
      });
  };

  return (
    <>
      <Link to="/reports" className="notifications-back-container">
        <img src={backarrow} alt="back arrow" className="notifications-back-icon" />
        <div className="notifications-back-text">Back</div>
      </Link>
      <div className="notifications-title-container">
        <div className="notifications-filter-container">
          <h1 className="notifications-title">{state.dates}</h1>
        </div>
      </div>
      {loading && (
        <div className="report-loading-container">
          <i alt="Loading icon" className="fa-solid fa-fire-extinguisher fa-beat fire-extinguisher-icon"></i>
          <div>Loading ...</div>
        </div>
      )}
      {!loading && !addresses && <div className="rc-error">No sessions found for this time period.</div>}
      {!loading && addresses && (
        <div className="report-card">
          <section className="report-card-stats-container">
            <div className="widget-container">
              <div className="send-email-report-container" onClick={() => setToggleEmailInput(!toggleEmailInput)}>
                <img src={emailIcon} alt="email report" className="report-email-icon" />
                <span>Send report via Email</span>
              </div>
              {toggleEmailInput && (
                <div className="report-email-popup">
                  <img src={close} alt="close icon" className="close-email-icon" onClick={() => setToggleEmailInput(false)}></img>
                  <label>
                    Email: <br></br>
                    <input type="email" id="email" style={{ margin: "5px auto 0 auto", width: "200px", border: "1px solid black", borderRadius: "3px", height: "25px" }}></input>
                  </label>
                  <div className="report-email-btn" onClick={sendEmail}>
                    Send
                  </div>
                </div>
              )}
              <div className="rc-container-small">
                <div className="report-card-text-container">
                  <div className="rc-icon-container">
                    <img className="report-stats-icon" src={fireIcon} alt="Firefighter icon"></img>
                    <span className="report-card-clock-font report-card-clock-font-large">{sessionCount}</span>
                  </div>
                  <span>Sessions</span>
                </div>
                <div className="report-card-text-container">
                  <div className="rc-icon-container">
                    <img className="report-stats-icon" src={ffIcon} alt="Firefighter icon"></img>
                    <span className="report-card-clock-font report-card-clock-font-large">{firefighters.length}</span>
                  </div>
                  <span>Firefighters</span>
                </div>
                <div className="report-card-text-container">
                  <div className="rc-icon-container">
                    <img className="report-stats-icon" src={clockIcon} alt="Clock icon"></img>
                    <div className="report-card-clock-font report-card-clock-font-large">
                      {Math.floor(totalLength / 3600) ? <div>{Math.floor(totalLength / 3600) + "h"}</div> : null}
                      <div>{Math.floor((totalLength % 3600) / 60).toString() + "m"}</div>
                    </div>
                  </div>
                  <span>Total</span>
                </div>
                <div className="report-card-text-container">
                  <div className="rc-icon-container">
                    <img className="report-stats-icon" src={locationIcon} alt="Location icon"></img>
                    <span className="report-card-clock-font report-card-clock-font-large">{addresses.length}</span>
                  </div>
                  <span>Locations</span>
                </div>
              </div>
              <div className="rc-container">
                {firefighters.length >= 3 && (
                  <div className="leader-board-stats-container">
                    <span>Time spent in sessions:</span>
                    <div className="report-card-leader-board report-card-winner">
                      <div className="image-and-rank-container">
                        <span style={{ backgroundColor: "blue" }} className="leader-board-ranking">
                          1st
                        </span>
                        <img className="report-card-image-placeholder" src={ffImages[firefighters[0].uuid] ? `data:image/jpeg;base64,${ffImages[firefighters[0].uuid]}` : ffprofile} alt="Profile" />
                      </div>
                      <span className="report-card-name winner-report-card-name">{firefighters[0]["name"]}</span>
                      <div className="report-card-clock-font ">
                        {Math.floor(firefighters[0]["total_length"] / 3600) ? <span>{Math.floor(firefighters[0]["total_length"] / 3600) + "h "}</span> : null}
                        <span>{Math.floor((firefighters[0]["total_length"] % 3600) / 60).toString() + "m"}</span>
                      </div>
                    </div>
                    <div className="rc-place-container">
                      <div className="report-card-leader-board">
                        <div className="image-and-rank-container">
                          <span style={{ backgroundColor: "green" }} className="leader-board-ranking">
                            2nd
                          </span>
                          <img className="report-card-image-placeholder" src={ffImages[firefighters[1].uuid] ? `data:image/jpeg;base64,${ffImages[firefighters[1].uuid]}` : ffprofile} alt="Profile" />
                        </div>
                        <span className="report-card-name">{firefighters[1]["name"]}</span>
                        <div className="report-card-clock-font ">
                          {Math.floor(firefighters[1]["total_length"] / 3600) ? <span>{Math.floor(firefighters[1]["total_length"] / 3600) + "h "}</span> : null}
                          <span>{Math.floor((firefighters[1]["total_length"] % 3600) / 60).toString() + "m"}</span>
                        </div>
                      </div>
                      <div className="report-card-leader-board">
                        <div className="image-and-rank-container">
                          <span style={{ backgroundColor: "orange" }} className="leader-board-ranking">
                            3rd
                          </span>
                          <img className="report-card-image-placeholder" src={ffImages[firefighters[2].uuid] ? `data:image/jpeg;base64,${ffImages[firefighters[2].uuid]}` : ffprofile} alt="Profile" />
                        </div>
                        <span className="report-card-name">{firefighters[2]["name"]}</span>
                        <div className="report-card-clock-font ">
                          {Math.floor(firefighters[2]["total_length"] / 3600) ? <span>{Math.floor(firefighters[2]["total_length"] / 3600) + "h "}</span> : null}
                          <span>{Math.floor((firefighters[2]["total_length"] % 3600) / 60).toString() + "m"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {firefighters.length >= 3 && (
                  <div className="leader-board-stats-container">
                    <span>Number of sessions:</span>
                    <div className="report-card-leader-board report-card-winner">
                      <div className="image-and-rank-container">
                        <span style={{ backgroundColor: "blue" }} className="leader-board-ranking">
                          1st
                        </span>
                        <img className="report-card-image-placeholder" src={ffImages[ffsMostSessions[0].uuid] ? `data:image/jpeg;base64,${ffImages[ffsMostSessions[0].uuid]}` : ffprofile} alt="Profile" />
                      </div>
                      <span className="report-card-name winner-report-card-name">{ffsMostSessions[0]["name"]}</span>
                      <span className="report-card-clock-font ">{ffsMostSessions[0]["total_sessions"]}</span>
                    </div>
                    <div className="rc-place-container">
                      <div className="report-card-leader-board">
                        <div className="image-and-rank-container">
                          <span style={{ backgroundColor: "green" }} className="leader-board-ranking">
                            2nd
                          </span>
                          <img className="report-card-image-placeholder" src={ffImages[ffsMostSessions[1].uuid] ? `data:image/jpeg;base64,${ffImages[ffsMostSessions[1].uuid]}` : ffprofile} alt="Profile" />
                        </div>
                        <span className="report-card-name">{ffsMostSessions[1]["name"]}</span>
                        <span className="report-card-clock-font ">{ffsMostSessions[1]["total_sessions"]}</span>
                      </div>
                      <div className="report-card-leader-board">
                        <div className="image-and-rank-container">
                          <span style={{ backgroundColor: "orange" }} className="leader-board-ranking">
                            3rd
                          </span>
                          <img className="report-card-image-placeholder" src={ffImages[ffsMostSessions[2].uuid] ? `data:image/jpeg;base64,${ffImages[ffsMostSessions[2].uuid]}` : ffprofile} alt="Profile" />
                        </div>
                        <span className="report-card-name">{ffsMostSessions[2]["name"]}</span>
                        <span className="report-card-clock-font ">{ffsMostSessions[2]["total_sessions"]}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
          <div className="report-chart-container">
            <ReportPlot plotID="timeInSessionsPlot" values={firefighters.map((ff) => ff["total_length"])} labels={firefighters.map((ff) => ff.name)} type="pie" title="Time in Sessions per Firefighter" textInfo={firefighters.map((ff) => convertSecondsToHHMM(ff["total_length"]))}></ReportPlot>
          </div>
          <div className="report-chart-container">
            <ReportPlot plotID="numOfSessionsPlot" values={firefighters.map((ff) => ff["total_sessions"])} labels={firefighters.map((ff) => ff.name)} type="pie" title="Sessions per Firefighter"></ReportPlot>
          </div>
          <div className="report-chart-container">
            <ReportPlot plotID="lengthPlot" textInfo={[convertSecondsToHHMM(averageLength), convertSecondsToHHMM(longestSession["session_length"])]} values={[averageLength, longestSession["session_length"]]} ticks={[averageLength, longestSession["session_length"]]} labels={["Average", "Longest"]} type="bar" title="Session Duration"></ReportPlot>
          </div>
          <div className="report-chart-container">
            <ReportPlot plotID="timePlot" values={Object.keys(times).map((time) => times[time])} labels={Object.keys(times).map((time) => timeOfDay(Number(time)))} xAxisLabel="Times" yAxisLabel="Sessions" type="bar" ticks="auto" title="Session Times"></ReportPlot>
          </div>
          {/* <div className="report-chart-container">
            <ReportPlot plotID="locationPlot" values={addresses.map((address) => address.count)} labels={addresses.map((address) => address.string)} type="pie" title="Address Breakdown"></ReportPlot>
          </div> */}
        </div>
      )}
    </>
  );
}
