import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import whiteLogo from "../../img/ascent_logo_white.png";
import Popup from "../popup/popup";
import backarrow from "../../img/backarrow.png";
import plus from "../../img/plus.png";
import select from "../../img/select.png";
import edit from "../../img/edit.png";
import "./deviceManager.scss";
import { DeviceContext, TabletsContext } from "../../Contexts.js";

export default function DeviceManager({ dept, config }) {
  const { tabletParam } = useParams();
  const navigate = useNavigate();
  const [tabletsVisible, setTabletsVisible] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [popupParams, setPopupParams] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // get rid of loading? need to rethink how to conditionally render this
  const [loading, setLoading] = useState(false);
  const { devices } = useContext(DeviceContext);
  const { tablets } = useContext(TabletsContext);
  const [currentTablet, setCurrentTablet] = useState(tablets.length ? tablets[0].tablet_id : "noDevices");

  useEffect(() => {
    // what is this tablet param thing? is this old?
    if (tabletParam && currentTablet === "") {
      setCurrentTablet(tabletParam);
    }
    if (tablets.length) {
      setCurrentTablet(tablets[0].tablet_id);
    }
  }, [tablets]);

  //  useEffect(() => {
  //    Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/tablets/fetch/${dept}`, config)
  //      .then((response) => {
  //        // setTablets(response.data.tablets);
  //        setLoading(false);
  //        // if no tablets, reset devices to none
  //        if (response.data.tablets.length === 0) {
  //          setCurrentTablet("noDevices");
  //          navigate(`/deviceManager/`);
  //        }
  //        // if no url parameter, get first tablet fetched
  //        else if (!tabletParam && currentTablet === "") {
  //          setCurrentTablet(response.data.tablets[0].tablet_id);
  //        }
  //        // if url parameter, use that as currentTablet
  //        else if (tabletParam && currentTablet === "") {
  //          setCurrentTablet(tabletParam);
  //        }
  //      })
  //      .catch((error) => {
  //        console.log(error);
  //        setLoading(false);
  //      });
  //  }, [currentTablet, tabletParam, message, navigate, config, dept]);

  // add a device to department
  const addDevice = (device, serialNumber) => {
    Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/department/addDevice`,
      {
        device: device,
        serialNumber: serialNumber,
        department: dept,
      },
      config
    )
      .then((response) => {
        console.log(response);
        closePopup();
        // set response message
        setMessage(response["data"]);
        setErrorMessage("");
      })
      .catch((error) => {
        console.log(error);
        setIsPopupOpen(false);
        setErrorMessage(error["response"]["data"]);
        setMessage("");
      });
  };

  // remove a device from tablet
  const removeDevice = (device) => {
    Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/department/removeDevice`,
      {
        device: device,
        department: dept,
      },
      config
    )
      .then((response) => {
        console.log(response);
        closePopup();
        // set response message
        setMessage(response["data"]);
        setErrorMessage("");
      })
      .catch((error) => {
        console.log(error);
        setIsPopupOpen(false);
        setErrorMessage(error["response"]["data"]);
        setMessage("");
      });
  };

  // edit the name of a device
  const editDevice = (currentDeviceName, newDeviceName) => {
    Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/devices/edit`,
      {
        currentDeviceName: currentDeviceName,
        newDeviceName: newDeviceName,
      },
      config
    )
      .then((response) => {
        console.log(response);
        closePopup();
        // set response message
        setMessage(response["data"]);
        setErrorMessage("");
      })
      .catch((error) => {
        console.log(error);
        setIsPopupOpen(false);
        setErrorMessage(error["response"]["data"]);
        setMessage("");
      });
  };

  // register a tablet from chief
  const registerTablet = (tablet) => {
    Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/tablet/register`,
      {
        tablet: tablet,
        department: dept,
      },
      config
    )
      .then((response) => {
        console.log(response);
        closePopup();
        // set response message
        setMessage(response["data"]);
        setErrorMessage("");
        // send select to new tablet page
        navigate(`/deviceManager/${tablet}`);
        setCurrentTablet(tablet);
      })
      .catch((error) => {
        console.log(error);
        setIsPopupOpen(false);
        setErrorMessage(error["response"]["data"]);
        setMessage("");
      });
  };

  // remove a tablet from chief
  const removeTablet = (tablet) => {
    Axios.post(
      `${process.env.REACT_APP_DB_SERVER_URL}/department/removeTablet`,
      {
        tablet: tablet,
        department: dept,
      },
      config
    )
      .then((response) => {
        console.log(response);
        closePopup();
        // set response message
        setMessage(response["data"]);
        setErrorMessage("");

        // setTablets
        var tempTablets = tablets;
        tempTablets = tempTablets.filter((currentTablet) => currentTablet.tablet_id !== tablet);
        console.log(tempTablets);

        // send select back to first tablet if tablet list not empty
        if (tempTablets.length > 0) {
          navigate(`/deviceManager/${tablets[0].tablet_id}`);
          setCurrentTablet(tempTablets[0].tablet_id);
        } else {
          navigate(`/deviceManager/`);
          setCurrentTablet("");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsPopupOpen(false);
        setErrorMessage(error["response"]["data"]);
        setMessage("");
      });
  };

  // open the popup
  const openPopup = (params) => {
    setIsCollapsing(false);
    setIsPopupOpen(true);
    setPopupParams(params);
  };

  // close the popup
  const closePopup = () => {
    setIsCollapsing(true);
    setTimeout(() => {
      setIsPopupOpen(false);
    }, 300);
  };

  function handleSelectChange(tablet) {
    navigate(`/deviceManager/${tablet}`);
    setCurrentTablet(tablet);
    setTabletsVisible(false);
  }

  return (
    <div>
      <div className="manager-container">
        <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img>
        <div className="title-container">
          <Link to="/" className="back-container">
            <img src={backarrow} alt="back arrow" className="back-icon"></img>
            <div className="back-text">Back</div>
          </Link>
          <h1 className="manager-title">Device Manager</h1>
        </div>
        {loading && (
          <div className="loading-container">
            <i alt="Loading icon" className="fa-solid fa-fire-extinguisher fa-beat fire-extinguisher-icon"></i>
            <div className="device-loading-text">Loading ...</div>
          </div>
        )}
        {!loading && (
          <>
            <div className="manager-label">Select a Tablet:</div>
            <div className="manager-filter">
              {tablets.length > 0 ? (
                <div className="tablet-select-container" onClick={() => setTabletsVisible(!tabletsVisible)}>
                  <div className={`tablet-select ${tabletsVisible ? "tablet-select-visible" : ""}`}>{tablets.length > 0 ? currentTablet : "You have no tablets!"}</div>
                  <div className={`tablet-options ${tabletsVisible ? "tablet-options-visible" : ""}`}>
                    {
                      // map the user's tablets
                      tablets.map((item, index) => (
                        <button className="tablet-option-button" key={index} onClick={() => handleSelectChange(item.tablet_id)}>
                          {item.tablet_id}
                        </button>
                      ))
                    }
                  </div>
                  <img src={select} alt="select icon" className="select-icon"></img>
                </div>
              ) : null}
              <div className="manager-tablet-buttons">
                {tablets.length > 0 ? (
                  <div>
                    <Link
                      className="manager-button remove"
                      onClick={() =>
                        openPopup({
                          action: removeTablet,
                          name: currentTablet,
                          title: "Remove",
                        })
                      }
                    >
                      Remove Tablet
                    </Link>
                  </div>
                ) : null}
                <div>
                  <Link
                    className="manager-button register"
                    onClick={() =>
                      openPopup({
                        action: registerTablet,
                        title: "Register Tablet",
                      })
                    }
                  >
                    Register Tablet
                  </Link>
                </div>
              </div>
            </div>
            {message.length > 0 ? (
              <div className="message">
                {message}
                <span
                  className="message-close-icon"
                  onClick={() => {
                    setMessage("");
                  }}
                >
                  &times;
                </span>
              </div>
            ) : null}
            {errorMessage.length > 0 ? (
              <div className="message">
                {errorMessage}
                <span
                  className="message-close-icon"
                  onClick={() => {
                    setErrorMessage("");
                  }}
                >
                  &times;
                </span>
              </div>
            ) : null}
            <div className={`popup-container ${isPopupOpen ? "expanded" : ""} ${isCollapsing ? "collapsing" : ""}`}>{isPopupOpen && <Popup params={popupParams} onClose={closePopup} />}</div>
            <div className="manager-label">Devices:</div>
            <div className="manager-cards-container">
              {devices.length > 0
                ? // map the tablet's devices
                  devices.map((item, index) => (
                    <div key={index} className="device-card">
                      <div className="inner-title-container">
                        <div className="manager-inner-title">{item.device_name ? item.device_name : item.device_id}</div>
                        <img
                          src={edit}
                          alt="edit icon"
                          className="edit-icon"
                          onClick={() =>
                            openPopup({
                              action: editDevice,
                              currentName: item.device_name ? item.device_name : item.device_id,
                              title: "Edit Device Name",
                            })
                          }
                        ></img>
                      </div>
                      <div className="manager-button-container">
                        <div
                          className="manager-button"
                          onClick={() =>
                            openPopup({
                              action: removeDevice,
                              name: item.device_name ? item.device_name : item.device_id,
                              title: "Remove",
                            })
                          }
                        >
                          Remove
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              <Link className="add-device-card" onClick={() => openPopup({ action: addDevice, title: "Add Device" })}>
                <div className="add-device-inner-card">
                  <img src={plus} alt="plus" className="plus-icon"></img>
                  <div className="add-device-text">Add New Device</div>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
