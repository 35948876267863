import React, { useRef} from 'react';
import './sessionVideo2.scss'

export default function SessionVideo(props) {
    const { index } = props;
    const videoRef = useRef(null);
    const streamUrl1 = `${process.env.REACT_APP_DB_SERVER_URL}/video/stream/ifsi_video`
    const streamUrl2 = `${process.env.REACT_APP_DB_SERVER_URL}/video/stream/example_video3`

    return index == 0 ? (
      <div className="session-video-container">
        <div className="video-title">Device 1</div>
        <div className="video-player">
          <video ref={videoRef} className="session-video" controls={true}>
            <source src={streamUrl1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    ) : index == 1 ? (
      <div className="session-video-container">
        <div className="video-title">Device 2</div>
        <div className="video-player">
          <video ref={videoRef} className="session-video" controls={true}>
            <source src={streamUrl2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    ) : null;
}
