import React, { useState } from 'react'
import close from '../../img/close.png'
import './popup.scss'

export default function Popup({params, onClose}) {
    const [name, setName] = useState(params["name"] ? params["name"] : "")
    const [newName, setNewName] = useState()
    const [serialNumber, setSerialNumber] = useState()

    return (
      <div className="popup">
        <div className="popup-header">
          <div className="popup-title">{params["title"]}</div>
          <img src={close} alt="close icon" className="close-icon" onClick={onClose}></img>
        </div>
        {params["name"] ? (
          <div className="popup-message">
            {" "}
            Are you sure you want to{" "}
            <b>
              {params["title"]} {params["name"]}
            </b>
            ?
          </div>
        ) : params["currentName"] ? (
          <div className="popup-input-container">
            {/* <div className="popup-label">
              New {params["title"].split(" ")[1]} Name for {params["currentName"]}:
            </div> */}
            <div className="popup-label">
              Rename {params["currentName"]}:
            </div>
            <input
              className="popup-input"
              type="text"
              id="name"
              name="name"
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
              required
            />
          </div>
        ) : (
          <>
            <div className="popup-input-container">
              <div className="popup-label">{params["title"].split(" ")[1]} Name:</div>
              <input
                className="popup-input"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            <div className="popup-input-container">
              <div className="popup-label">Serial Number:</div>
              <input
                className="popup-input"
                type="text"
                id="serialNumber"
                name="serialNumber"
                value={serialNumber}
                onChange={(e) => {
                  setSerialNumber(e.target.value);
                }}
                required
              />
            </div>
          </>
        )}
        <div className="confirm-button-container">
          <button className="confirm-button cancel" onClick={onClose}>
            Cancel
          </button>
          {params["currentName"] ? (
            <button className="confirm-button" onClick={() => params["action"](params["currentName"], newName)}>
              Confirm
            </button>
          ) : (
            <button className="confirm-button" onClick={() => params["action"](name, serialNumber)}>
              Confirm
            </button>
          )}
        </div>
      </div>
    );
}