import React, { useEffect, useState } from "react"
import SessionSearch from "../sessionSearch/sessionSearch"
import Plot from "react-plotly.js"
import { Link, useParams } from "react-router-dom"
import whiteLogo from "../../img/ascent_logo_white.png"
import backarrow from "../../img/backarrow.png"
import "./device.scss"
import Axios from "axios"

export default function Device({ config }) {
  const { deviceID, deviceName } = useParams()
  const [sessionCount, setSessionCount] = useState(0)
  const [sessionLengths, setSessionLengths] = useState({})
  const [maxThermalStates, setMaxThermalStates] = useState({})
  const [batteryUsage, setBatteryUsage] = useState([])
  const maxRGB = 255
  const lifespanDivisor = 1

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_DB_SERVER_URL}/device/fetch/data/${deviceID}`, config
    )
      .then((response) => {
        setSessionCount(response.data.device.sessionCount)
        setSessionLengths(response.data.device.sessionLengths)
        setMaxThermalStates(response.data.device.maxThermalStates)
        setBatteryUsage(response.data.device.batteryUsage)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [deviceID, config])

  // get progress bar color
  const calculateColor = (percentage) => {
    let redRGB, greenRGB

    if (percentage <= 0.5) {
      // Interpolate between green and yellow
      redRGB = Math.round(maxRGB * (percentage * 2))
      greenRGB = maxRGB
    } else {
      // Interpolate between yellow and red
      redRGB = maxRGB
      greenRGB = Math.round(maxRGB * ((1 - percentage) * 2))
    }

    const redHex = redRGB.toString(16).padStart(2, "0").toUpperCase()
    const greenHex = greenRGB.toString(16).padStart(2, "0").toUpperCase()

    return `#${redHex}${greenHex}00`
  }

  function getMarkerColor(value) {
    if (value > 20) return "red"
    if (value >= 10 && value < 20) return "yellow"
    return "green"
  }

  function DeviceChart(props) {
    return (
      <div className="device-chart">
        <Plot
          data={[
            props.type === "bar"
              ? {
                  x: props.xData,
                  y: props.yData,
                  type: props.type,
                  marker: {
                    color: [
                      "purple",
                      "blue",
                      "green",
                      "yellow",
                      "orange",
                      "red",
                    ],
                  },
                }
              : props.type === "pie"
              ? {
                  labels: props.xData,
                  values: props.yData,
                  type: props.type,
                  marker: {
                    colors: ["blue", "green", "yellow", "orange"],
                  },
                }
              : props.type === "scatter"
              ? {
                  type: "scatter",
                  mode: "markers",
                  x: props.xData,
                  y: props.yData,
                  marker: {
                    color: props.yData.map((value) => getMarkerColor(value)),
                    size: 12,
                  },
                }
              : {},
          ]}
          layout={{
            title: props.title,
            xaxis: {
              title: props.xTitle,
            },
            yaxis: {
              title: props.yTitle,
              dtick: 5,
            },
            autosize: true,
          }}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="device-container">
        <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img>
        <div className="device-title-container">
          <Link to="/" className="device-back-container">
            <img src={backarrow} alt="back arrow" className="device-back-icon"></img>
            <div className="device-back-text">Back</div>
          </Link>
          <h2 className="manager-title">{deviceName}</h2>
        </div>
        {sessionCount ? <div className="lifespan-container">
          <div
            className="lifespan-bar"
            style={{
              width: `${sessionCount / lifespanDivisor}%`,
              backgroundColor: calculateColor(sessionCount / lifespanDivisor / 100),
              transition: `width 1s ease`,
            }}
          ></div>
          {sessionCount > 0 && (
            <div className="lifespan-label-container">
              <div className="lifespan-label">Lifespan: {Math.round(sessionCount / lifespanDivisor)}%</div>
            </div>
          )}
        </div> : null}
        {/* <div className="device-info-container">
                <div className="device-info">Incidents Completed: {sessionCount}</div>
                {
                    Object.keys(deviceData).length > 0 &&
                    <>
                        <div className="device-info">Average Incident Time: {Object.keys(deviceData).length ? deviceData.sessionAvg : null} sec</div>
                        <div className="device-info">Most Recent Incident: {Object.keys(deviceData).length ? formatDate(deviceData.recentDatetime) : null}</div>
                    </>
                }
            </div> */}
        {sessionCount > 0 && (
          <div className="device-charts-container">
            <DeviceChart xData={Object.keys(sessionLengths).map((key) => Number(key) / 60)} yData={Object.values(sessionLengths)} title="Session Length" yTitle="# Sessions" xTitle="Session Length (minutes)" type="bar" />
            <DeviceChart data={maxThermalStates} xData={Object.keys(maxThermalStates)} yData={Object.values(maxThermalStates)} title="Max Thermal States" type="pie" />
            <DeviceChart xData={batteryUsage.map((session) => session["sessionLength"]).map((key) => Number(key) / 60)} yData={batteryUsage.map((session) => session["batteryUsage"])} title="Battery Usage vs. Session Length" type="scatter" yTitle="Battery Usage (%)" xTitle="Session Length (minutes)" />
            <DeviceChart xData={batteryUsage.map((session) => session["maxThermalState"])} yData={batteryUsage.map((session) => session["batteryUsage"])} title="Battery Usage vs. Thermal State" type="scatter" yTitle="Battery Usage (%)" xTitle="Thermal State" />
          </div>
        )}
        <SessionSearch device={deviceID} />
      </div>
    </div>
  );
}
