import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from 'react-three-fiber';
import * as THREE from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import { OrbitControls } from '@react-three/drei';
import loadCloud from '../../img/load_cloud.png';
import loadTraversal from '../../img/load_traversal.png';
import finishTraversal from '../../img/finish_traversal.png';
import './pointCloud.scss'

function TraversalModel({ path, reset, setReset, fastForward, setFastForward }) {
    const cube = useRef();
    const intervalRef = useRef();
    const [pathIndex, setPathIndex] = useState(0);
    // const [visiblePath, setVisiblePath] = useState([]);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (pathIndex < path.length) {
                setPathIndex((prev) => prev + 1);
            }
        }, 10);

        return () => {
            clearInterval(intervalRef.current);
        }
    }, [])

    useFrame(() => {
        if (path && cube.current) {
            const point = path[pathIndex];
            if (point) {
                cube.current.position.set(point[0], point[1], point[2]);
                // setVisiblePath(path.slice(0, pathIndex + 1));
            }
        }
    });

    // Reset pathIndex when reset is true
    useEffect(() => {
        if (reset) {
            setPathIndex(0);
            setReset(false);
        }
    }, [reset, setReset]);

    // set pathIndex to end when fastForward is true
    useEffect(() => {
        if (fastForward) {
            setPathIndex(path.length - 1);
            setFastForward(false);
        }
    }, [fastForward, setFastForward])

    return (
        <>
            {
                pathIndex < path.length &&
                
                <mesh ref={cube}>
                    <boxGeometry args={[1, 1, 1]} />
                    <meshStandardMaterial color={'orange'} />
                </mesh>
            }
        </>

    );
}

export default function PointCloud(props) {
    const mesh = useRef();
    const line = useRef();
    const [isMeshReady, setIsMeshReady] = useState();
    const [isLineReady, setIsLineReady] = useState();
    const [traversalRunning, setTraversalRunning] = useState(false);
    const [reset, setReset] = useState(false);
    const [fastForward, setFastForward] = useState(false);

    // Load the GLTF model
    // const gltf = useLoader(GLTFLoader, '/moss_wood_4k.gltf');

    useEffect(() => {
        if (props.pcdFile && mesh.current) {
            // use PCDLoader to load the .pcd file
            const url = URL.createObjectURL(props.pcdFile);
            const loader = new PCDLoader();
            loader.load(url, (points) => {
                mesh.current.geometry = points.geometry;
                mesh.current.material = points.material;
                mesh.current.position.copy(points.position);
                mesh.current.rotation.copy(points.rotation);

                // Clean up the URL object after the load is complete
                URL.revokeObjectURL(url);
            });
            // cleanup
            return () => {
                if (mesh.current) {
                    mesh.current.geometry.dispose();
                    mesh.current.material.dispose();
                }
            }
        }
    }, [props.pcdFile, isMeshReady])

    useEffect(() => {
        if (props.path && line.current) {
            // Create a geometry for the line
            const points = props.path.map(p => new THREE.Vector3(...p));
            const geometry = new THREE.BufferGeometry().setFromPoints(points);

            // Create a line with the geometry and a basic material
            line.current.geometry.dispose();
            line.current.geometry = geometry;
            // line.current.material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        }
    }, [props.path, isLineReady]);

    return (
        props.pcdFile && props.path &&
        <div className='point-cloud-container'>
            <Canvas 
                style={{ backgroundColor: 'black', position: 'relative', height: "600px" }}
                camera={{ position: [0, 5, 5], up: [0, 0, 1], fov: 75 }}
            >
                <ambientLight intensity={1} />
                <points ref={mesh} onUpdate={() => {
                    // The onUpdate callback is executed after the component is rendered and attached.
                    // It's a good place to set state that depends on the component being present.
                    setIsMeshReady(true);
                    console.log('Mesh has been attached:', mesh.current);
                }}>
                    <pointsMaterial size={0.25} />
                </points>
                {
                    traversalRunning &&

                    <>
                        <line ref={line} onUpdate={() => {
                            setIsLineReady(true);
                            console.log('Line has been attached:', line.current);
                        }}>
                            <bufferGeometry attach="geometry" />
                            <lineBasicMaterial attach="material" color={0x5AAFDE} linewidth={5} />
                        </line>
                        <TraversalModel path={props.path} reset={reset} setReset={setReset} fastForward={fastForward} setFastForward={setFastForward}/>
                    </>
                }
                <OrbitControls />
            </Canvas>
            <div className='load-cloud-button' onClick={() => {
                setTraversalRunning(false);
                setIsLineReady(false);
            }}>
                <img className='point-cloud-icon' src={loadCloud} alt="load cloud"/>
            </div>
            <div className='start-traversal-button' onClick={() => {
                if (!traversalRunning) {
                    setTraversalRunning(true);
                } else {
                    setReset(true);
                }
            }}>
                <img className='point-cloud-icon' src={loadTraversal} alt="start traversal"/>
            </div>
            {
                traversalRunning &&

                <div className='finish-traversal-button' onClick={() => {
                    setFastForward(true);
                }}>
                    <img className='point-cloud-icon' src={finishTraversal} alt="load traversal"/>
                </div>
            }
        </div>
    )
}
