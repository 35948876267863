import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import whiteLogo from "../../img/ascent_logo_white.png";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import "./login.scss";

export default function Login({ handleKeyPress }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const login = async (e) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      if (userCredential.user.emailVerified) {
        navigate("/");
      } else {
        sendEmailVerification(userCredential.user)
          .then(() => {
            console.log("Verification email sent");
          })
          .catch(() => {
            console.log("Failed to send verification email");
          });
        setError("This email address has not been verified yet. Please check your inbox for the verification email.");
      }
    } catch (err) {
      console.log(err.message);
      // format error message
      var errorMessage = err.message.split("/")[1].split(")")[0];
      errorMessage = errorMessage.split("-").map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
      errorMessage = errorMessage.join(" ");
      setError(errorMessage);
    }
  };

  return (
    <div>
      <div className="login-container">
        <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img>
        <div className="login-subcontainer">
          <div className="login-title">Login</div>
          <div className="login-input-container">
            <div className="login-label">Email: </div>
            <input
              className="login-input"
              type="text"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyUp={(event) => handleKeyPress(event)}
            />
          </div>
          <div className="login-input-container">
            <div className="login-label">Password: </div>
            <input
              className="login-input"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyUp={(event) => handleKeyPress(event)}
            />
          </div>
          {error ? <div className="firebase-error">{error}</div> : null}
          <button className="login-button" onClick={login}>
            Login
          </button>
        </div>
        {/* <Link to="/register" className="to-register">
          Register for a New Account
        </Link> */}
      </div>
    </div>
  );
}
