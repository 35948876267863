import React, { createContext, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

// Fetch Devices
const DeviceContext = createContext(null);
const DeviceProvider = ({ children, config, dept, admin }) => {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const fetchDeviceData = async () => {
      let deviceUrl = `${process.env.REACT_APP_DB_SERVER_URL}/devices/fetch/department/${dept}`;
      if (admin) {
        deviceUrl = `${process.env.REACT_APP_DB_SERVER_URL}/devices/fetch/all`;
      }
      Axios.get(deviceUrl, config)
        .then((response) => {
          // Some kind of deep comparision to see if the data changed first
          setDevices(response.data.devices);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchDeviceData();
  }, [admin, config, dept]);

  return <DeviceContext.Provider value={{ devices }}>{children}</DeviceContext.Provider>;
};

export { DeviceContext, DeviceProvider };

// Fetch Tablets
const TabletsContext = createContext(null);
const TabletsProvider = ({ children, config, dept }) => {
  const [tablets, setTablets] = useState([]);

  useEffect(() => {
    const fetchTabletData = async () => {
      Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/tablets/fetch/${dept}`, config)
        .then((response) => {
          setTablets(response.data.tablets);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchTabletData();
  }, [config, dept]);

  return <TabletsContext.Provider value={{ tablets }}>{children}</TabletsContext.Provider>;
};

export { TabletsContext, TabletsProvider };

// Fetch Sessions
const SessionsContext = createContext(null);
const SessionsProvider = ({ children, config, dept, admin, user }) => {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const fetchSessionsData = async () => {
      let sessionUrl = admin ? `${process.env.REACT_APP_DB_SERVER_URL}/sessions/fetch/all/${100}` : `${process.env.REACT_APP_DB_SERVER_URL}/sessions/fetch/department/${dept}/${20}`;
      if (user && user.uid) {
        Axios.post(
          sessionUrl,
          {
            chief_uuid: user.uid,
          },
          config
        )
          .then((response) => {
            setSessions(response.data.sessions.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    fetchSessionsData();
  }, [admin, config, dept, user]);

  return <SessionsContext.Provider value={{ sessions }}>{children}</SessionsContext.Provider>;
};

export { SessionsContext, SessionsProvider };

// Fetch Firefighters
const FFContext = createContext(null);
const FFProvider = ({ children, dept, admin, config }) => {
  const [firefighters, setFirefighters] = useState([]);

  useEffect(() => {
    const fetchFFData = async () => {
      let ffUrl = admin ? `${process.env.REACT_APP_DB_SERVER_URL}/firefighters/fetch/all` : `${process.env.REACT_APP_DB_SERVER_URL}/firefighters/fetch/department/${dept}`;

      Axios.get(ffUrl, config)
        .then((response) => {
          setFirefighters(response.data.firefighters);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchFFData();
  }, [admin, config, dept]);

  return <FFContext.Provider value={{ firefighters }}>{children}</FFContext.Provider>;
};

export { FFContext, FFProvider };

// Fetch Firefighter Profiles
const FFProfilesContext = createContext(null);
const FFProfilesProvider = ({ children, config }) => {
  const [firefighterProfiles, setFirefighterProfiles] = useState([]);
  const { firefighters } = useContext(FFContext);

  useEffect(() => {
    const fetchFFProfileData = async () => {
      if (firefighters && firefighters.length) {
        Axios.post(
          `${process.env.REACT_APP_DB_SERVER_URL}/firefighters/fetch/profiles`,
          {
            firefighters: firefighters,
          },
          config
        )
          .then((response) => {
            setFirefighterProfiles(response.data.images);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    fetchFFProfileData();
  }, [firefighters, config]);

  return <FFProfilesContext.Provider value={{ firefighterProfiles }}>{children}</FFProfilesContext.Provider>;
};

export { FFProfilesContext, FFProfilesProvider };

// Fetch Single Session Data
const SessionContext = createContext(null);
const SessionProvider = ({ children, config }) => {
  const [sessionData, setSessionData] = useState({});
  const { sessionParam } = useParams();

  useEffect(() => {
    const fetchSessionData = async () => {
      if (sessionParam) {
        Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/analytics/${sessionParam}`, config)
          .then((response) => {
            setSessionData(response.data.sessionData);
          })
          .catch((error) => {
            console.log(error);
            setSessionData("error");
          });
      }
    };

    fetchSessionData();
  }, [sessionParam, config]);

  return <SessionContext.Provider value={{ sessionData }}>{children}</SessionContext.Provider>;
};

export { SessionContext, SessionProvider };
