import React, { useEffect, useState } from "react";
import "./reports.scss";
import { Link } from "react-router-dom";
import backarrow from "../../img/backarrow.png";
import close from "../../img/close.png";
import select from "../../img/select.png";
import Axios from "axios";

export default function Reports({user, config}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupPage, setPopupPage] = useState(1);
  const [email, setEmail] = useState(undefined);
  const [newEmail, setNewEmail] = useState(email);
  const [emailFrequency, setEmailFrequency] = useState(undefined);
  const [newFrequency, setNewFrequency] = useState(emailFrequency);
  const [showFrequencies, setShowFrequencies] = useState(false);
  const [dates, setDates] = useState({
    Weekly: undefined,
    Monthly: undefined,
    Quarterly: undefined,
    Yearly: undefined,
    YTD: undefined,
  });
  const [cardFrequency, setCardFrequency] = useState("Weekly");
  const [firstDate, setFirstDate] = useState(undefined);
  let currentDay = new Date();
  currentDay.setHours(0, 0, 0, 0);
  const periods = ["Weekly", "Monthly", "Quarterly", "Yearly", "YTD"];

  const formatDate = (date, format = "default") => {
    const options = format === "YTD" ? { year: "2-digit", month: "2-digit", day: "2-digit" } : { year: "2-digit", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const getNextWeeklySegment = (date) => {
    let start = new Date(date);
    while (start.getDay() !== 0) {
      start.setDate(start.getDate() + 1);
    }
    let end = new Date(start);
    end.setDate(end.getDate() + 6);
    return [start, end];
  };

  const getNextMonthlySegment = (date) => {
    let start = new Date(date.getFullYear(), date.getMonth(), 1);
    let end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    return [start, end];
  };

  const getNextQuarterlySegment = (date) => {
    const quarter = Math.floor(date.getMonth() / 3);
    let start = new Date(date.getFullYear(), quarter * 3, 1);
    let end = new Date(start.getFullYear(), start.getMonth() + 3, 0);
    return [start, end];
  };

  const getNextYearlySegment = (date) => {
    let start = new Date(date.getFullYear(), 0, 1);
    let end = new Date(start.getFullYear() + 1, 0, 0);
    return [start, end];
  };

  const setDateSegments = (startDate, endDate, segmentType) => {
    const segments = [];
    let start = new Date(startDate);

    const getNextSegmentDates = {
      Weekly: getNextWeeklySegment,
      Monthly: getNextMonthlySegment,
      Quarterly: getNextQuarterlySegment,
      Yearly: getNextYearlySegment,
    };

    while (start < endDate) {
      const [segmentStart, segmentEnd] = getNextSegmentDates[segmentType](start);

      if (segmentEnd <= endDate) {
        segments.push(`${formatDate(segmentStart)} - ${formatDate(segmentEnd)}`);
      }
      start = new Date(segmentEnd.getTime() + 24 * 60 * 60 * 1000);
    }

    return segments.reverse();
  };

  const createReportCards = (period, firstDate, currentDay) => {
    setCardFrequency(period);

    if (period === "YTD") {
      setDates({
        ...dates,
        YTD: [`${formatDate(new Date(firstDate), "YTD")} - ${formatDate(new Date(currentDay), "YTD")}`],
      });
      return;
    }

    setDates({
      ...dates,
      [period]: setDateSegments(firstDate, currentDay, period),
    });
  };

  const getStationData = async () => {
    Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/session/fetch/earliest/${user.uid}`, config)
      .then((response) => {
        let date = new Date(response.data.datetime);
        date.setHours(0, 0, 0, 0);
        setFirstDate(date);
        setEmail(response.data["report_email"]);
        setNewEmail(response.data["report_email"]);
        let emailPref = response.data["email_preference"];
        setEmailFrequency(emailPref.charAt(0).toUpperCase() + emailPref.slice(1));
        setCardFrequency("Weekly");
        createReportCards("Weekly", date, currentDay);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateReportInfo = () => {
    setEmail(newEmail);
    setEmailFrequency(newFrequency);
    setIsPopupOpen(false);
    setPopupPage(1);
    let updatedInfo = { report_email: newEmail, email_preference: newFrequency.toLowerCase() };

    Axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/chief/update/${user.uid}`, updatedInfo, config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setShowFrequencies(false);
  }, [newFrequency]);

  useEffect(() => {
    if (!isPopupOpen) {
      setPopupPage(1);
      setNewEmail(email);
      setNewFrequency(emailFrequency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen]);

  return (
    <>
      <Link to="/" className="notifications-back-container">
        <img src={backarrow} alt="back arrow" className="notifications-back-icon" />
        <div className="notifications-back-text">Back</div>
      </Link>
      <div className="notifications-title-container">
        <div className="notifications-filter-container">
          <h1 className="notifications-title">Summary Reports</h1>
        </div>
      </div>
      <div className="report-checkbox-container">
        Report Period:
        {periods.map((option, index) => (
          <label className="report-checkbox" key={index}>
            <input type="radio" onChange={() => createReportCards(option, firstDate, currentDay)} checked={option === cardFrequency} />
            {option === "YTD" ? "Lifetime Report" : option}
          </label>
        ))}
      </div>
      {emailFrequency && emailFrequency !== "Never" && !isPopupOpen && (
        <div className="notification-settings-info">
          You are currently subscribed to receive <span style={{ fontWeight: "bold" }}>{emailFrequency.toLowerCase()}</span> email reports at <span style={{ fontWeight: "bold" }}>{email}</span>,
          <span className="notification-settings" onClick={() => setIsPopupOpen(true)}>
            change preferences.
          </span>
        </div>
      )}
      {emailFrequency === "Never" && !isPopupOpen && (
        <div className="notification-settings-info">
          You have opted out of receiving email reports,
          <span className="notification-settings" onClick={() => setIsPopupOpen(true)}>
            change preferences.
          </span>
        </div>
      )}
      {isPopupOpen && (
        <div className="notifications-popup">
          <div className="popup-header-ff">
            <div className="popup-title-ff">Email Report Settings</div>
            <img src={close} alt="close icon" className="close-icon" onClick={() => setIsPopupOpen(false)} />
          </div>
          {popupPage === 1 ? (
            <>
              <div className="popup-input-container-ff">
                <div className="popup-label-ff">Email: </div>
                <input className="popup-input-ff" type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
              </div>
              <div className="confirm-button-container-ff">
                <button className="confirm-button-ff cancel-ff" onClick={() => setIsPopupOpen(false)}>
                  Cancel
                </button>
                {!newEmail ? (
                  <button type="button" className="confirm-button-ff-disabled" disabled>
                    Next
                  </button>
                ) : (
                  <button className="confirm-button-ff" onClick={() => setPopupPage(2)}>
                    Next
                  </button>
                )}
              </div>
              <div className="popup-ff-page-number">1 of 3</div>
            </>
          ) : popupPage === 2 ? (
            <>
              <div className="popup-input-container-ff">
                <div className="popup-label-ff">Send email report:</div>
                <div>
                  <div value={newFrequency ? newFrequency : emailFrequency} className="frequency-select-container" onClick={() => setShowFrequencies(!showFrequencies)}>
                    {newFrequency ? newFrequency : emailFrequency}
                    <img src={select} alt="select icon" className="select-icon"></img>
                  </div>
                  {showFrequencies && (
                    <>
                      <div className="notification-frequency-option" onClick={() => setNewFrequency("Weekly")}>
                        Weekly
                      </div>
                      <div className="notification-frequency-option" onClick={() => setNewFrequency("Monthly")}>
                        Monthly
                      </div>
                      <div className="notification-frequency-option" onClick={() => setNewFrequency("Quarterly")}>
                        Querterly
                      </div>
                      <div className="notification-frequency-option" onClick={() => setNewFrequency("Yearly")}>
                        Yearly
                      </div>
                      <div className="notification-frequency-option" onClick={() => setNewFrequency("Never")}>
                        Never
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="confirm-button-container-ff">
                <button className="confirm-button-ff cancel-ff" onClick={() => setPopupPage(1)}>
                  Back
                </button>
                <button className="confirm-button-ff" onClick={() => setPopupPage(3)}>
                  Next
                </button>
              </div>
              <div className="popup-ff-page-number">2 of 3</div>
            </>
          ) : (
            <>
              <div className="popup-label-ff">Email reports to: {newEmail}</div>
              <div className="popup-label-ff">Recieve reports by email: {newFrequency || emailFrequency}</div>
              <div className="confirm-button-container-ff">
                <button className="confirm-button-ff cancel-ff" onClick={() => setPopupPage(2)}>
                  Back
                </button>
                <button className="confirm-button-ff" onClick={updateReportInfo}>
                  Confirm
                </button>
              </div>
              <div className="popup-ff-page-number">3 of 3</div>
            </>
          )}
        </div>
      )}
      <div className="manager-cards-container report-cards-container">
        {dates[cardFrequency] && dates[cardFrequency].length
          ? dates[cardFrequency].map((item, index) => (
              <Link key={index} style={{ textDecoration: "none", color: "black" }} to="/report" state={{ dates: item, period: cardFrequency }}>
                <div key={index} className="device-card">
                  <div className="report-title-container">
                    <div className="report-card-title">{cardFrequency === "YTD" ? "Lifetime" : cardFrequency} Report:</div>
                    <div>{item}</div>
                  </div>
                  <div className="manager-button-container"></div>
                </div>
              </Link>
            ))
          : null}
        {cardFrequency && !dates[cardFrequency] ? (
          <div className="device-card">
            <div className="report-title-container">
              <div className="report-card-title">No {cardFrequency.toLowerCase()} reports found for this department.</div>
              <div></div>
            </div>
            <div className="manager-button-container"></div>
          </div>
        ) : null}
      </div>
    </>
  );
}
