import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import "./firefighterManager.scss";
import whiteLogo from "../../img/ascent_logo_white.png";
import backarrow from "../../img/backarrow.png";
import plus from "../../img/plus.png";
import minus from "../../img/minus.png";
import close from "../../img/close.png";
import edit from "../../img/edit.png";
import trash from "../../img/trash.png";
import upload from "../../img/upload.png";
import ffprofile from "../../img/ffprofile2.png";
import { FFContext, FFProfilesContext } from "../../Contexts.js";

export default function FirefighterManager({ user, dept, config }) {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [popupParams, setPopupParams] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const canvasRef = useRef(null);
  const { firefighters } = useContext(FFContext);
  const { firefighterProfiles } = useContext(FFProfilesContext);

  // convert a blob to a file
  async function blobToFile(blobUrl, fileName) {
    return fetch(blobUrl)
      .then((response) => response.blob())
      .then((blob) => {
        var file = new File([blob], fileName, { type: "image/png" });
        console.log(file);
        return file;
      });
  }

  // add a firefighter to the database
  const addFirefighter = (name, uuid, nfcs, imageFile) => {
    // create formData to send image file
    const formData = new FormData();
    formData.append("name", name);
    // could be manually typed in depending on NFC implementation
    formData.append("uuid", uuid);
    formData.append("nfcs", JSON.stringify(nfcs));
    formData.append("theme", randomHexColor());

    // await for the blob to be converted before sending the request
    blobToFile(imageFile, "profile.png").then((file) => {
      formData.append("profile", file);

      Axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/firefighters/add/${dept}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer: ${user.accessToken}`,
        },
      })
        .then((response) => {
          closePopup();
          console.log(response["data"]);
          setMessage(response.data);
          setErrorMessage("");
        })
        .catch((error) => {
          closePopup();
          console.log(error);
          setErrorMessage(error.message);
          setMessage("");
        });
    });
  };

  const addFirefightersByFile = () => {
    const formData = new FormData();
    formData.append("csvFile", uploadFile);
    Axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/firefighters/add/file/${dept}`, formData, config)
      .then((response) => {
        closePopup();
        console.log(response["data"]);
        setMessage(response.data);
        setErrorMessage("");
      })
      .catch((error) => {
        closePopup();
        console.log(error);
        setErrorMessage(error.message);
        setMessage("");
      });
  };

  // update firefigher info
  const updateFirefighter = (name, uuid, nfcs, imageFile) => {
    // create formData to send image file
    const formData = new FormData();
    formData.append("name", name);
    formData.append("uuid", uuid);
    formData.append("nfcs", JSON.stringify(nfcs));
    // await for the blob to be converted before sending the request
    blobToFile(imageFile, "profile.png").then((file) => {
      formData.append("profile", file);

      Axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/firefighters/update/${uuid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer: ${user.accessToken}`,
        },
      })
        .then((response) => {
          closePopup();
          console.log(response["data"]);
          setMessage(response.data);
          setErrorMessage("");
        })
        .catch((error) => {
          closePopup();
          console.log(error);
          setErrorMessage(error.message);
          setMessage("");
        });
    });
  };

  // remove firefighter from department
  const removeFirefighter = (uuid) => {
    Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/firefighters/remove/${uuid}`, config)
      .then((response) => {
        closePopup();
        console.log("removeFirefighter", response.data);
        setMessage(response.data);
        setErrorMessage("");
      })
      .catch((error) => {
        closePopup();
        console.log("removeFirefighterError", error);
        setErrorMessage(error.message);
        setMessage("");
      });
  };

  // generate a random hex color
  function randomHexColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // open the popup
  const openPopup = (params, popupType) => {
    setIsCollapsing(false);
    if (popupType === "edit") {
      setIsEditPopupOpen(true);
    } else if (popupType === "remove") {
      setIsRemovePopupOpen(true);
    } else if (popupType === "upload") {
      setIsUploadPopupOpen(true);
    }
    setPopupParams(params);
  };

  // close the popup
  const closePopup = () => {
    setIsCollapsing(true);
    setTimeout(() => {
      setIsEditPopupOpen(false);
      setIsRemovePopupOpen(false);
      setIsUploadPopupOpen(false);
    }, 300);
  };

  const cropImage = (file, setImageFile) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const cropSize = Math.min(img.width, img.height); // This will crop to a square based on the smaller dimension

        console.log("cropSize", cropSize);
        // Use the canvas ref here
        const canvas = canvasRef.current;
        canvas.width = cropSize;
        canvas.height = cropSize;
        const ctx = canvas.getContext("2d");

        // Calculate the top left corner of the cropped image
        const offsetX = (img.width - cropSize) / 2;
        const offsetY = (img.height - cropSize) / 2;

        ctx.drawImage(img, offsetX, offsetY, cropSize, cropSize, 0, 0, cropSize, cropSize);
        canvas.toBlob((blob) => {
          const croppedImage = URL.createObjectURL(blob);
          setImageFile(croppedImage);
        }, "image/jpeg"); // Adjust the format as needed
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  function EditPopup(props) {
    const { params } = props;
    const [name, setName] = useState(params ? params.name : "");
    const [uuid, setUuid] = useState(params ? params.uuid : "");
    const [nfcs, setNfcs] = useState([]);
    const [nfcCount, setNfcCount] = useState(0);
    const [imageFile, setImageFile] = useState(params && firefighterProfiles[params.uuid] ? `data:image/jpeg;base64,${firefighterProfiles[params.uuid]}` : null);
    const [popupPage, setPopupPage] = useState(1);

    return (
      <div className="popup-ff">
        <div className="popup-header-ff">
          <div className="popup-title-ff">{params ? "Edit Firefighter" : "Add Firefighter"}</div>
          <img src={close} alt="close icon" className="close-icon" onClick={closePopup}></img>
        </div>
        {popupPage === 1 ? (
          <>
            <div className="popup-input-container-ff">
              <div className="popup-label-ff">Name:</div>
              <input
                className="popup-input-ff"
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
            </div>
            {!params && (
              <div className="popup-input-container-ff">
                <div className="popup-label-ff">Firefighter UUID:</div>
                <input
                  className="popup-input-ff"
                  type="text"
                  id="uuid"
                  name="uuid"
                  value={uuid}
                  onChange={(e) => {
                    setUuid(e.target.value);
                  }}
                  required
                />
              </div>
            )}
            <div className="popup-input-container-ff">
              <div className="popup-nfc-label-container">
                <div className="popup-label-ff-nfc">Add NFC UUIDs:</div>
                {nfcCount > 0 && (
                  <div
                    className="add-nfc-icon-container"
                    onClick={() => {
                      setNfcCount(nfcCount - 1);
                      setNfcs((prevNfcs) => {
                        return prevNfcs.slice(0, prevNfcs.length - 1);
                      });
                    }}
                  >
                    <img className="add-nfc-icon" src={minus} alt="Minus"></img>
                  </div>
                )}
                {nfcCount < 3 && (
                  <div
                    className="add-nfc-icon-container"
                    onClick={() => {
                      setNfcCount(nfcCount + 1);
                      setNfcs((prevNfcs) => {
                        return [...prevNfcs, ""];
                      });
                    }}
                  >
                    <img className="add-nfc-icon" src={plus} alt="Plus"></img>
                  </div>
                )}
              </div>
              {nfcs.map((item, index) => {
                return (
                  <input
                    key={`nfc-${index}`}
                    className="popup-input-nfc"
                    type="text"
                    id="uuid"
                    name={`nfc-${index}`}
                    value={item}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setNfcs((prevNfcs) => {
                        const updatedNfcs = [...prevNfcs];
                        updatedNfcs[index] = e.target.value;
                        return updatedNfcs;
                      });
                    }}
                  />
                );
              })}
            </div>
            <div className="confirm-button-container-ff">
              <button className="confirm-button-ff cancel-ff" onClick={closePopup}>
                Cancel
              </button>
              <button
                className="confirm-button-ff"
                onClick={() => {
                  setPopupPage(2);
                  setNfcs((prevNfcs) => {
                    const updatedNfcs = [...prevNfcs].filter((item) => item !== "");
                    setNfcCount(updatedNfcs.length);
                    console.log(updatedNfcs);
                    return updatedNfcs;
                  });
                }}
              >
                Next
              </button>
            </div>
            <div className="popup-ff-page-number">1 of 3</div>
          </>
        ) : popupPage === 2 ? (
          <>
            <div className="popup-input-container-ff">
              <div className="image-upload-popup-container">
                <img src={imageFile ? imageFile : ffprofile} alt="Upload Profile" className="image-upload-popup" />
                <canvas ref={canvasRef} style={{ display: "none" }} />
              </div>
              <label className="image-upload-popup-label" htmlFor="image-upload">
                Upload Profile Picture
              </label>
              <input type="file" id="image-upload" accept="image/*" className="input-no-display" onChange={(e) => cropImage(e.target.files[0], setImageFile)} required />
            </div>
            <div className="confirm-button-container-ff">
              <button
                className="confirm-button-ff cancel-ff"
                onClick={() => {
                  setPopupPage(1);
                }}
              >
                Back
              </button>
              <button
                className="confirm-button-ff"
                onClick={() => {
                  setPopupPage(3);
                }}
              >
                Next
              </button>
            </div>
            <div className="popup-ff-page-number">2 of 3</div>
          </>
        ) : (
          <>
            <div className="popup-label-ff">Confirm information is correct.</div>
            <div className="popup-label-ff">Name: {name}</div>
            <div className="popup-label-ff">Firefighter UUID: {uuid}</div>
            {nfcCount > 0 && (
              <>
                <div className="popup-label-ff">NFC UUIDs: </div>
                {nfcs.map((item, index) => {
                  return (
                    <div key={index} className="popup-label-ff">
                      {item}
                    </div>
                  );
                })}
              </>
            )}
            <div className="confirm-button-container-ff">
              <button
                className="confirm-button-ff cancel-ff"
                onClick={() => {
                  setPopupPage(2);
                }}
              >
                Back
              </button>
              <button
                className="confirm-button-ff"
                onClick={
                  params
                    ? () =>
                        updateFirefighter(
                          name,
                          uuid,
                          nfcs.filter((item) => item !== ""),
                          imageFile
                        )
                    : () => {
                        addFirefighter(
                          name,
                          uuid,
                          nfcs.filter((item) => item !== ""),
                          imageFile
                        );
                      }
                }
              >
                Confirm
              </button>
            </div>
            <div className="popup-ff-page-number">3 of 3</div>
          </>
        )}
      </div>
    );
  }

  function RemovePopup(props) {
    const { params } = props;

    return (
      <div className="popup-ff">
        <div className="popup-header-ff">
          <div className="popup-title-ff">Remove Firefighter</div>
          <img src={close} alt="close icon" className="close-icon" onClick={closePopup}></img>
        </div>
        <div className="popup-label-ff popup-label-ff-center">Remove this Firefighter?</div>
        <img src={`data:image/jpeg;base64,${params.profile}`} alt="profile" className="popup-ff-remove-profile" />
        <div className="popup-label-ff">Name: {params.name}</div>
        <div className="popup-label-ff">Firefighter UUID: {params.uuid}</div>
        <div className="confirm-button-container-ff">
          <button className="confirm-button-ff remove-button" onClick={() => removeFirefighter(params.uuid)}>
            Confirm
          </button>
        </div>
      </div>
    );
  }

  function UploadPopup() {
    return (
      <div className="popup-ff">
        <div className="popup-header-ff">
          <div className="popup-title-ff">Upload File</div>
          <img src={close} alt="close icon" className="close-icon" onClick={closePopup}></img>
        </div>
        <div className="popup-upload-input-container">
          <input
            className="popup-upload-input"
            type="file"
            onChange={(e) => {
              setUploadFile(e.target.files[0]);
            }}
          />
          {uploadFile ? (
            <>
              <div className="popup-upload-input-text">{uploadFile.name}</div>
            </>
          ) : (
            <>
              <img className="popup-upload-icon" src={upload} alt="upload"></img>
              <div className="popup-upload-input-text">Drag and Drop Your Files</div>
            </>
          )}
        </div>
        <div className="confirm-button-container-ff">
          <button className="confirm-button-ff cancel-ff" onClick={closePopup}>
            Back
          </button>
          <button className="confirm-button-ff" onClick={uploadFile ? () => addFirefightersByFile() : null}>
            Confirm
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="manager-container">
        <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img>
        <div className="title-container">
          <Link to="/" className="back-container">
            <img src={backarrow} alt="back arrow" className="back-icon"></img>
            <div className="back-text">Back</div>
          </Link>
          <h1 className="manager-title">Firefighter Manager</h1>
        </div>
        {message.length > 0 ? (
          <div className="message">
            {message}
            <span
              className="message-close-icon"
              onClick={() => {
                setMessage("");
              }}
            >
              &times;
            </span>
          </div>
        ) : null}
        {errorMessage.length > 0 ? (
          <div className="message">
            {errorMessage}
            <span
              className="message-close-icon"
              onClick={() => {
                setErrorMessage("");
              }}
            >
              &times;
            </span>
          </div>
        ) : null}
        <div className="ff-manager-upload-button" onClick={() => openPopup(null, "upload")}>
          Upload File
        </div>
        <div className="ff-manager-cards-container">
          <Link className="ff-manager-card add-ff-card" onClick={() => openPopup(null, "edit")}>
            <div className="add-ff-icon-container">
              <img className="add-ff-icon" src={plus} alt="Plus"></img>
            </div>
            <div className="ff-manager-name">Add Firefighter</div>
          </Link>
          {firefighters.length > 0 ? (
            <>
              {firefighters.map((item, index) => (
                <div className="ff-manager-card" key={index}>
                  <div
                    className="ff-manager-edit-icon-container"
                    onClick={() => {
                      openPopup(item, "edit");
                    }}
                  >
                    <img className="ff-manager-edit-icon" src={edit} alt="Edit FF" />
                  </div>
                  <div
                    className="ff-manager-remove-icon-container"
                    onClick={() => {
                      openPopup(item, "remove");
                    }}
                  >
                    <img className="ff-manager-edit-icon" src={trash} alt="Edit FF" />
                  </div>
                  <Link to={`/firefighter/${item.uuid}`} className="ff-manager-card-link">
                    <img
                      className="ff-manager-profile"
                      style={{
                        backgroundColor: item.theme,
                      }}
                      src={firefighterProfiles[item.uuid] ? `data:image/jpeg;base64,${firefighterProfiles[item.uuid]}` : ffprofile}
                      alt="Profile"
                    />
                  </Link>
                  <div className="ff-manager-name">{item.name}</div>
                </div>
              ))}
            </>
          ) : null}
          <div className={`popup-container ${isEditPopupOpen ? "expanded" : ""} ${isCollapsing ? "collapsing" : ""}`}>{isEditPopupOpen && <EditPopup params={popupParams} onClose={closePopup} />}</div>
          <div className={`popup-container ${isRemovePopupOpen ? "expanded" : ""} ${isCollapsing ? "collapsing" : ""}`}>{isRemovePopupOpen && <RemovePopup params={popupParams} onClose={closePopup} />}</div>
          <div className={`popup-container ${isUploadPopupOpen ? "expanded" : ""} ${isCollapsing ? "collapsing" : ""}`}>{isUploadPopupOpen && <UploadPopup onClose={closePopup} />}</div>
        </div>
      </div>
    </div>
  );
}
