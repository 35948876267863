import React, { useEffect, useRef, useState } from "react";
import Plotly from "plotly.js-dist";
import "./plot.scss";

export default function ReportPlot(props) {
  const plotRef = useRef(null);
  const [data, setData] = useState(undefined);
  const [layout, setLayout] = useState({
    title: undefined,
    height: (props.labels && props.labels.length > 25) ? 900 : 600,
    width: undefined,
  });

  useEffect(() => {
    const currentPlotRef = plotRef.current;

    Plotly.newPlot(props.plotID, data, layout, { responsive: true });

    return () => {
      if (currentPlotRef) {
        Plotly.purge(currentPlotRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (props.type === "pie") {
      setData([
        {
          ...data,
          values: props.values,
          labels: props.labels,
          type: props.type,
          text: props.textInfo ? props.labels.map((name, index) => props.labels[index] + " - " + props.textInfo[index]) : props.values.map((val, index) => props.labels[index] + " - " + val),
          textinfo: "text",
          textposition: "inside",
          textfont: {
            size: 12,
          },
          automargin: false,
          hoverinfo: "percent+text",
        },
      ]);
      setLayout({
        ...layout,
        title: props.title,
        margin: {
          b: 40,
          t: 100,
          l: 0,
          r: 0,
        },
        showlegend: true,
        legend: { orientation: "h" },
      });
    } else if (props.type === "bar") {
      setData([
        {
          ...data,
          y: props.values,
          x: props.labels,
          type: props.type,
          marker: {
            color: ["yellow", "blue", "red", "orange", "green", "purple", "pink", "yellow", "blue", "red", "orange", "green", "purple", "pink", "yellow", "blue", "red", "orange", "green", "purple", "pink", "yellow", "blue", "red"],
          },
        },
      ]);
      setLayout({
        ...layout,
        title: props.title,
        xaxis: {
          nticks: props.labels.length,
          title: {
            text: props.xAxisLabel,
          },
        },
        yaxis: {
          tickvals: props.ticks,
          ticktext: props.textInfo,
          title: {
            text: props.yAxisLabel,
          },
        },
        margin: {
          l: 50,
          r: 30,
        },
        legend: {
          itemwidth: 20,
          orientation: "h",
        },
      });
    }
  }, [props, data, layout]);

  return <div className="plot-container-ref" ref={plotRef} id={props.plotID} />;
}
