import React, { useState } from "react";
// import { Link } from "react-router-dom";
import whiteLogo from "../../img/ascent_logo_white.png";
import Axios from "axios";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import "./register.scss";

export default function Register({ config, handleKeyPress }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [registered, setRegistered] = useState(false);
  const [error, setError] = useState("");

  const register = (e) => {
    e.preventDefault();

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("User created on FireBase");
        const user = userCredential.user;
        const uuid = userCredential.user.uid;

        return Axios.post(
          `${process.env.REACT_APP_DB_SERVER_URL}/chief/register`,
          {
            uuid: uuid,
            email: email,
            name: name,
            department: department,
          },
          config
        )
          .then(() => {
            console.log("User created on Mongo");
            sendEmailVerification(user);
          })
          .then(() => {
            console.log("Verification email sent");
            return updateProfile(auth.currentUser, { displayName: name });
          })
          .then(() => {
            console.log("Display name updated");
            return Axios.post(
              `${process.env.REACT_APP_DB_SERVER_URL}/claim/create`,
              {
                uuid: uuid,
                department: department,
              },
              config
            );
          })
          .then(() => {
            console.log("Custom claims set");
            setRegistered(true);
          })
          .catch((error) => {
            // This catches any errors in the Axios.posts or sendEmailVerification promises
            console.error("Error", error);
            setError(error.message);
          });
      })
      .catch((err) => {
        // This catches errors in the createUserWithEmailAndPassword promise
        console.error("Error in createUserWithEmailAndPassword", err);
        setError(err.message.split("Firebase: ")[1]);
      });
  };

  return (
    <div>
      <div>
        {!registered ? (
          <div className="register-container">
            <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img>
            <div className="register-subcontainer">
              <div className="register-title">Create an Account</div>
              <div className="register-input-row-container">
                <div className="register-input-container">
                  <div className="register-label">Email: </div>
                  <input
                    className="register-input"
                    type="email"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyUp={(event) => handleKeyPress(event)}
                  />
                </div>
                <div className="register-input-container">
                  <div className="register-label">Name: </div>
                  <input
                    autoComplete="off"
                    placeholder="Name"
                    className="register-input"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onKeyUp={(event) => handleKeyPress(event)}
                  />
                </div>
              </div>
              <div className="register-input-row-container">
                <div className="register-input-container">
                  <div className="register-label">Password: </div>
                  <input
                    autoComplete="off"
                    placeholder="Password"
                    className="register-input"
                    type="text"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyUp={(event) => handleKeyPress(event)}
                  />
                </div>
                <div className="register-input-container">
                  <div className="register-label">Fire Department: </div>
                  <input
                    autoComplete="off"
                    placeholder="Department"
                    className="register-input"
                    type="text"
                    onChange={(e) => {
                      setDepartment(e.target.value);
                    }}
                    onKeyUp={(event) => handleKeyPress(event)}
                  />
                </div>
              </div>
              {error ? <div className="firebase-error">{error}</div> : null}
              <button className="register-button" onClick={register}>
                Register
              </button>
            </div>
            {/* <Link to="/login" className="to-login">
              Back to Login
            </Link> */}
          </div>
        ) : null}
      </div>
    </div>
  );
}
