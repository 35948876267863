import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [idToken, setIdToken] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        user.getIdToken().then(setIdToken);
      } else {
        setIdToken("");
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    idToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
