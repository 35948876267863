import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import whiteLogo from "../../img/ascent_logo_white.png";
import backarrow from "../../img/backarrow.png";
import starEmpty from "../../img/star_empty.png";
import starFilled from "../../img/star_filled.png";
import "./sessionSearch.scss";
import { SessionsContext } from "../../Contexts.js";

export default function SessionSearch(props) {
  const { sessions } = useContext(SessionsContext);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [favorites, setFavorites] = useState(undefined);
  const [startDate, setStartDate] = useState(localStorage.getItem("startDate") ? new Date(localStorage.getItem("startDate")) : "");
  const [endDate, setEndDate] = useState(localStorage.getItem("endDate") ? new Date(localStorage.getItem("endDate")) : "");
  const [startTime, setStartTime] = useState(localStorage.getItem("startTime") ? localStorage.getItem("startTime") : "");
  const [endTime, setEndTime] = useState(localStorage.getItem("endTime") ? localStorage.getItem("endTime") : "");
  const [address, setAddress] = useState(localStorage.getItem("address") ? localStorage.getItem("address") : "");
  const [minLength, setMinLength] = useState(localStorage.getItem("minLength") ? localStorage.getItem("minLength") : "");
  const [maxLength, setMaxLength] = useState(localStorage.getItem("maxLength") ? localStorage.getItem("maxLength") : "");
  const [deptSearch, setDeptSearch] = useState(localStorage.getItem("deptSearch") ? localStorage.getItem("deptSearch") : "");
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    Date: localStorage.getItem("startDate") || localStorage.getItem("endDate") ? true : false,
    Time: localStorage.getItem("startTime") || localStorage.getItem("endTime") ? true : false,
    Location: localStorage.getItem("address") ? true : false,
    Duration: localStorage.getItem("minLength") || localStorage.getItem("maxLength") ? true : false,
    Department: localStorage.getItem("deptSearch") ? true : false,
  });
  let filterFields = props.admin ? ["Date", "Time", "Location", "Duration", "Department"] : ["Date", "Time", "Location", "Duration"];
  let options = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };
  let linkOptions = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };

  const getFavorites = () => {
    if (props.fetchFavorites) {
      props
        .fetchFavorites(startDate, endDate, startTime, endTime, address, +minLength, +maxLength, deptSearch)
        .then((res) => {
          setFavorites(res);
        })
        .catch((error) => {
          setFavorites([]);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getFavorites();
    // eslint-disable-next-line
  }, [filteredSessions]);

  useEffect(() => {
    if (!filters.Date) {
      setStartDate(null);
      setEndDate(null);
      localStorage.removeItem("startDate");
      localStorage.removeItem("endDate");
    }

    if (!filters.Time) {
      setStartTime(null);
      setEndTime(null);
      localStorage.removeItem("startTime");
      localStorage.removeItem("endTime");
    }

    if (!filters.Location) {
      setAddress(null);
      localStorage.removeItem("address");
    }

    if (!filters.Department) {
      setDeptSearch(null);
      localStorage.removeItem("deptSearch");
    }

    if (!filters.Duration) {
      setMinLength(null);
      setMaxLength(null);
      localStorage.removeItem("minLength");
      localStorage.removeItem("maxLength");
    }

    if (!filters.Duration && !filters.Department && !filters.Location && !filters.Time && !filters.Date && sessions) {
      setFilteredSessions(sessions);
    }
  }, [filters.Date, filters.Time, filters.Location, filters.Duration, filters.Department, sessions]);

  useEffect(() => {
    if (props.tablet && props.config) {
      // get the tablet's sessions from mongo
      Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/sessions/fetch/tablet/${props.tablet}`, props.config)
        .then((response) => {
          setFilteredSessions(response.data.sessions);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (props.device && props.config) {
      // get the devices's sessions from mongo
      Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/sessions/fetch/device/${props.device}`, props.config)
        .then((response) => {
          setFilteredSessions(response.data.sessions);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [props.tablet, props.device, props.admin, props.config, props.dept]);

  const fetchSessions = () => {
    // fetch all sessions if user has admin clearance
    let sessionUrl = `${process.env.REACT_APP_DB_SERVER_URL}/sessions/fetch/department/${props.dept}/${100}`;
    if (props.admin) {
      sessionUrl = `${process.env.REACT_APP_DB_SERVER_URL}/sessions/fetch/all/${100}`;
    }
    // get the user's sessions from mongo
    Axios.post(
      sessionUrl,
      {
        chief_uuid: props.user.uid,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        address: address,
        min_length: +minLength,
        max_length: +maxLength,
        department: deptSearch,
      },
      props.config
    )
      .then((response) => {
        setFilteredSessions(response.data.sessions.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setFilteredSessions([]);
      });
  };

  // debounce logic, only calls fetchSessions when user is done typing
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (startDate || endDate || startTime || endTime || address || minLength || maxLength || deptSearch) {
        fetchSessions();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, startTime, endTime, address, minLength, maxLength, deptSearch]);

  function formatSeconds(seconds) {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  return (
    <>
      <div className="session-search-container">
        {!props.device ? <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img> : null}
        <div className={"session-search-filter-container"}>
          {!props.device ? (
            <div className="search-title-container">
              <Link to="/" className="search-back-container">
                <img src={backarrow} alt="back arrow" className="back-icon"></img>
                <div className="ss-back-text">Back</div>
              </Link>
              <h1 className="session-search-title">Sessions</h1>
            </div>
          ) : null}
          {loading && (
            <div className="loading-container search-loading-container">
              <i alt="Loading icon" className="fa-solid fa-fire-extinguisher fa-beat fire-extinguisher-icon"></i>
              <div className="device-loading-text">Loading ...</div>
            </div>
          )}
          {!loading && (
            <div className="ss-checkbox-container">
              Filters:
              {filterFields.map((option, index) => (
                <label className="report-checkbox sessions-checkbox" key={index}>
                  <input
                    type="checkbox"
                    checked={filters[option]}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        [option]: !filters[option],
                      })
                    }
                  />
                  {option}
                </label>
              ))}
            </div>
          )}
          {!loading && (
            <div className="session-filter-container">
              {filters.Date && (
                <div className="filter-container">
                  <label className="filter-label-visible">
                    Start Date:
                    <input
                      className="filter-visible"
                      type={"date"}
                      max={new Date().toISOString().split("T")[0]}
                      value={startDate instanceof Date ? startDate.toISOString().split("T")[0] : ""}
                      onChange={(e) => {
                        setStartDate(e.target.value ? new Date(e.target.value + "T00:00:00") : null);
                        if (e.target.value) {
                          localStorage.setItem("startDate", e.target.value + "T00:00:00");
                        }
                      }}
                      onKeyUp={(event) => props.handleKeyPress(event)}
                    />
                  </label>
                  <label className="filter-label-visible">
                    End Date:
                    <input
                      className="filter-visible ss-second-input"
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                      value={endDate instanceof Date ? endDate.toISOString().split("T")[0] : ""}
                      onChange={(e) => {
                        setEndDate(e.target.value ? new Date(e.target.value + "T00:00:00") : null);
                        if (e.target.value) {
                          localStorage.setItem("endDate", e.target.value + "T00:00:00");
                        }
                      }}
                      onKeyUp={(event) => props.handleKeyPress(event)}
                    />
                  </label>
                </div>
              )}
              {filters.Time && (
                <div className="filter-container">
                  <label className="filter-label-visible">
                    Start Time:
                    <input
                      className="filter-visible ss-second-input"
                      type="time"
                      value={startTime ? startTime : ""}
                      onChange={(e) => {
                        setStartTime(e.target.value);
                        localStorage.setItem("startTime", e.target.value);
                      }}
                      onKeyUp={(event) => props.handleKeyPress(event)}
                    />
                  </label>
                  <label className="filter-label-visible">
                    End Time:
                    <input
                      className="filter-visible"
                      type="time"
                      value={endTime ? endTime : ""}
                      onChange={(e) => {
                        setEndTime(e.target.value);
                        localStorage.setItem("endTime", e.target.value);
                      }}
                      onKeyUp={(event) => props.handleKeyPress(event)}
                    />
                  </label>
                </div>
              )}
              {filters.Location && (
                <div>
                  <label className="address-label-visible">
                    Search by address, street, city, or zip code:
                    <div className="input-icon-container">
                      <input
                        className="filter-visible"
                        type="text"
                        value={address ? address : ""}
                        placeholder="Enter Address"
                        onChange={(e) => {
                          setAddress(e.target.value);
                          localStorage.setItem("address", e.target.value);
                        }}
                        onKeyUp={(event) => props.handleKeyPress(event)}
                      ></input>
                      {/* <img src={locationIcon} alt="location icon" className={`location-icon ${filterField === "location" ? "location-icon-visible" : ""}`}></img> */}
                    </div>
                  </label>
                </div>
              )}
              {filters.Duration && (
                <div className="filter-container">
                  <label className="filter-label-visible">
                    Min (minutes) :
                    <input
                      className="filter-visible"
                      type="number"
                      id="minDuration"
                      value={+minLength ? +minLength : ""}
                      placeholder="Min Duration"
                      onChange={(e) => {
                        setMinLength(e.target.value ? parseFloat(e.target.value) : "");
                        localStorage.setItem("minLength", e.target.value ? parseFloat(e.target.value) : "");
                      }}
                      onKeyUp={(event) => props.handleKeyPress(event)}
                    ></input>
                    {/* <img src={clockIcon} alt="clock icon" className={`clock-icon ${filterField === "length" ? "clock-icon-visible" : ""}`}></img> */}
                  </label>
                  <label className="filter-label-visible">
                    Max (minutes) :
                    <input
                      className="filter-visible"
                      type="number"
                      value={maxLength ? maxLength : ""}
                      placeholder="Max Duration"
                      onChange={(e) => {
                        setMaxLength(e.target.value ? parseFloat(e.target.value) : "");
                        localStorage.setItem("maxLength", e.target.value ? parseFloat(e.target.value) : "");
                      }}
                      onKeyUp={(event) => props.handleKeyPress(event)}
                    ></input>
                    {/* <img src={clockIcon} alt="clock icon" className={`clock-icon ${filterField === "length" ? "clock-icon-visible" : ""}`}></img> */}
                  </label>
                </div>
              )}
              {filters.Department && props.admin && (
                <div>
                  <label className="address-label-visible">
                    Dept.
                    <div className="input-icon-container">
                      <input
                        className="filter-visible"
                        type="text"
                        placeholder="Department"
                        value={deptSearch ? deptSearch : ""}
                        onChange={(e) => {
                          setDeptSearch(e.target.value);
                          localStorage.setItem("deptSearch", e.target.value);
                        }}
                        onKeyUp={(event) => props.handleKeyPress(event)}
                      ></input>
                      {/* <img src={locationIcon} alt="location icon" className={`location-icon ${filterField === "location" ? "location-icon-visible" : ""}`}></img> */}
                    </div>
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {filteredSessions && filteredSessions.length ? (
        <div className="search-card">
          <div className="search-session-header-container">
            <div className="search-session-cell-favorite search-session-cell-favorite-header hidden-row search-header cell-overflow"></div>
            <div className="search-session-cell search-header cell-overflow">Date</div>
            <div className="search-session-cell search-header cell-overflow">Time</div>
            <div className="search-session-cell hidden-row search-header cell-overflow">Location</div>
            <div className="search-session-cell hidden-row search-header cell-overflow">Length</div>
            {props.admin && <div className="search-session-cell search-header cell-overflow">Dept.</div>}
          </div>
          {favorites && favorites.length && !loading
            ? favorites.map(
                (item, index) =>
                  // Limit the sessions shown
                  index < 100 && (
                    <Link to={`/session/${item.session_id}/${new Date(item.datetime).toLocaleString("en-US", linkOptions)}`} className="search-session-row" key={index}>
                      <div className="search-session-cell-favorite cell-overflow hidden-row">
                        <img
                          className="favorite-icon"
                          src={starFilled}
                          alt="star"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            props
                              .updateFavorites(item.session_id)
                              .then(() => {
                                getFavorites();
                              })
                              .then(() => {
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              });
                          }}
                        />
                      </div>
                      <div className="search-session-cell cell-overflow">{new Date(item.datetime).toLocaleString("en-US", options).split(", ")[0]}</div>
                      <div className="search-session-cell cell-overflow">{new Date(item.datetime).toLocaleString("en-US", options).split(", ")[1]}</div>
                      {/* <div className="search-session-cell cell-overflow">{item.tablet_id}</div> */}
                      {/* <div className="search-session-cell hidden-row">{item.numDevices}</div> */}
                      <div className="search-session-cell cell-overflow hidden-row session-address">{item.address}</div>
                      <div className="search-session-cell cell-overflow hidden-row">{item.session_length ? formatSeconds(item.session_length) : ""}</div>
                      {props.admin && <div className="search-session-cell cell-overflow">{item.department}</div>}
                    </Link>
                  )
              )
            : null}
          {filteredSessions && filteredSessions.length && !loading
            ? filteredSessions.map((item, index) => (
                <Link to={`/session/${item.session_id}/${new Date(item.datetime).toLocaleString("en-US", linkOptions)}`} className="search-session-row" key={index}>
                  <div className="search-session-cell-favorite cell-overflow hidden-row">
                    <img
                      className="favorite-icon"
                      src={starEmpty}
                      alt="star"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        props
                          .updateFavorites(item.session_id)
                          .then(() => {
                            getFavorites();
                          })
                          .then(() => {
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          });
                      }}
                    />
                  </div>
                  <div className="search-session-cell cell-overflow">{new Date(item.datetime).toLocaleString("en-US", options).split(", ")[0]}</div>
                  <div className="search-session-cell cell-overflow">{new Date(item.datetime).toLocaleString("en-US", options).split(", ")[1]}</div>
                  {/* <div className="search-session-cell cell-overflow">{item.tablet_id}</div> */}
                  {/* <div className="search-session-cell hidden-row">{item.numDevices}</div> */}
                  <div className="search-session-cell cell-overflow hidden-row session-address">{item.address}</div>
                  <div className="search-session-cell cell-overflow hidden-row">{item.session_length ? formatSeconds(item.session_length) : ""}</div>
                  {props.admin && <div className="search-session-cell cell-overflow">{item.department}</div>}
                </Link>
              ))
            : null}
        </div>
      ) : null}
      {!filteredSessions && !filteredSessions.length && Object.values(filters).some((value) => value === true) ? <div className="no-sessions">No sessions match your search filters.</div> : null}
      {!filteredSessions && !filteredSessions.length && Object.values(filters).every((value) => value === false) && !loading ? <div className="no-sessions">You have no sessions!</div> : null}
    </>
  );
}
