import React, { useRef, useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import Axios from "axios"
import "./firefighter.scss"
import whiteLogo from "../../img/ascent_logo_white.png"
import backarrow from "../../img/backarrow.png"
import rightarrow from "../../img/rightarrow.png"
import leftarrow from "../../img/leftarrow.png"
import ffprofile from "../../img/ffprofile.png"
import edit from "../../img/edit.png"
import trash from "../../img/trash.png"
import rhrPlot from "../../img/rhrplot.png"
import rechrPlot from "../../img/rechrplot.png"
import ahrPlot from "../../img/ahrplot.png"
import hrvPlot from "../../img/hrvplot.png"
import recrrPlot from "../../img/recrrplot.png"
import restrrPlot from "../../img/restrrplot.png"
import arrPlot from "../../img/arrplot.png"
import resprvPlot from "../../img/resprvplot.png"
import restolPlot from "../../img/restolplot.png"
import recolPlot from "../../img/recolplot.png"
import aolPlot from "../../img/aolplot.png"
import aovPlot from "../../img/aovplot.png"
import hrSessionPlot from "../../img/hrsessionplot.png"
import tempSessionPlot from "../../img/tempsessionplot.png"
import GPSPlot from "../plot/gpsPlot"
import Plot from "../plot/plot"

export default function Firefighter({ config }) {
  const { uuid } = useParams()
  const pageRef = useRef(null)
  const [firefighterData, setFirefighterData] = useState({})
  const [sessions, setSessions] = useState([])
  const [currentSession, setCurrentSession] = useState({})
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0)
  const chartTypes = ["Heart Rate", "Respitory Rate", "Oxygen Levels"]
  const charts = [
    [rhrPlot, ahrPlot, rechrPlot, hrvPlot],
    [restrrPlot, arrPlot, recrrPlot, resprvPlot],
    [restolPlot, aolPlot, recolPlot, aovPlot],
  ]
  const [currentChartType, setCurrentChartType] = useState(chartTypes[0])
  const [currentChartTypeIndex, setCurrentChartTypeIndex] = useState(0)
  const [odometryData, setOdometryData] = useState({})
  const [gpsData, setGpsData] = useState({})
  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }

  // fetch firefighter data
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_DB_SERVER_URL}/firefighters/fetch/${uuid}`, config
    )
      .then((response) => {
        setFirefighterData(response.data.firefighter)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [uuid, config])

  // fetch firefighter sessions
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_DB_SERVER_URL}/firefighters/fetch/sessions/${uuid}`, config
    )
      .then((response) => {
        if (response.data.sessions.length > 0) {
          setSessions(response.data.sessions)
          setCurrentSession(response.data.sessions[0])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [uuid, config])

  useEffect(() => {
    if (Object.keys(currentSession).length > 0) {
      Axios.get(
        `${process.env.REACT_APP_DB_SERVER_URL}/analytics/odometry/${currentSession.session_id}/${uuid}`, config
      )
        .then((response) => {
          setOdometryData(response.data.odometry)
        })
        .catch((error) => {
          console.log("odometryFetchError", error)
        })
    }
  }, [currentSession, uuid, config])

  useEffect(() => {
    if (Object.keys(currentSession).length > 0) {
      Axios.get(
        `${process.env.REACT_APP_DB_SERVER_URL}/analytics/gps/${currentSession.session_id}/${uuid}`, config
      )
        .then((response) => {
          setGpsData(response.data.gps)
        })
        .catch((error) => {
          console.log("gpsFetchError", error)
        })
    }
  }, [currentSession, uuid, config])

  const handleChartTypeChange = (indexChange) => {
    if (currentChartTypeIndex + indexChange < 0) {
      // if negative index, go to end of session list
      setCurrentChartType(chartTypes[chartTypes.length - 1])
      setCurrentChartTypeIndex(chartTypes.length - 1)
    } else if (currentChartTypeIndex + indexChange > chartTypes.length - 1) {
      // if index over list length, go back to start of list
      setCurrentChartType(chartTypes[0])
      setCurrentChartTypeIndex(0)
    } else {
      // normal case
      setCurrentChartType(chartTypes[currentChartTypeIndex + indexChange])
      setCurrentChartTypeIndex((prevIndex) => {
        return prevIndex + indexChange
      })
    }
  }

  const handleSessionChange = (indexChange) => {
    if (currentSessionIndex + indexChange < 0) {
      // if negative index, go to end of session list
      setCurrentSession(sessions[sessions.length - 1])
      setCurrentSessionIndex(sessions.length - 1)
    } else if (currentSessionIndex + indexChange > sessions.length - 1) {
      // if index over list length, go back to start of list
      setCurrentSession(sessions[0])
      setCurrentSessionIndex(0)
    } else {
      // normal case
      setCurrentSession(sessions[currentSessionIndex + indexChange])
      setCurrentSessionIndex((prevIndex) => {
        return prevIndex + indexChange
      })
    }
  }

  return (
    <div>
      <div className="ff-container" ref={pageRef}>
        <img src={whiteLogo} alt="ascent logo white" className="white-logo"></img>
        <div className="ff-header-container">
          <Link to="/firefighterManager" className="ff-back-container">
            <img src={backarrow} alt="back arrow" className="back-icon"></img>
            <div className="ff-back-text">Back</div>
          </Link>
          <div className="ff-slider-container">
            <img
              className="ff-arrow-icon"
              alt="left arrow"
              src={leftarrow}
              onClick={() => {
                handleChartTypeChange(-1);
              }}
            ></img>
            <div className="ff-slider-text">{currentChartType}</div>
            <img
              className="ff-arrow-icon"
              alt="right arrow"
              src={rightarrow}
              onClick={() => {
                handleChartTypeChange(1);
              }}
            ></img>
          </div>
        </div>
        <div className="ff-card-container">
          <div className="ff-card-data-column">
            <div className="ff-data-card large">
              <div className="ff-profile-container">
                <img
                  className="ff-profile"
                  style={{
                    backgroundColor: firefighterData.theme,
                  }}
                  src={firefighterData.profile ? `data:image/jpeg;base64,${firefighterData.profile}` : ffprofile}
                  alt="Profile"
                />
                <div className="ff-edit-icon-container">
                  <img className="ff-edit-icon" src={edit} alt="Edit FF" />
                </div>
                <div className="ff-remove-icon-container">
                  <img className="ff-edit-icon" src={trash} alt="Edit FF" />
                </div>
              </div>
              <div className="ff-title">{firefighterData.name}</div>
              <div className="ff-data-row">
                <div className="ff-data-point ff-data-point-title">UUID:</div>
                <div className="ff-data-point">{firefighterData.uuid}</div>
              </div>
              <div className="ff-data-row">
                <div className="ff-data-point ff-data-point-title">Department:</div>
                <div className="ff-data-point">{firefighterData.department}</div>
              </div>
              <div className="ff-data-row">
                <div className="ff-data-point ff-data-point-title">Rank:</div>
                <div className="ff-data-point">Firefighter</div>
              </div>
              <div className="ff-data-row">
                <div className="ff-data-point ff-data-point-title">Station:</div>
                <div className="ff-data-point">CFD Engine 18</div>
              </div>
              <div className="ff-data-row">
                <div className="ff-data-point ff-data-point-title">Height:</div>
                <div className="ff-data-point">6 ft</div>
              </div>
              <div className="ff-data-row">
                <div className="ff-data-point ff-data-point-title">Weight:</div>
                <div className="ff-data-point">170 lbs</div>
              </div>
              {/* total distance travelled, use thermal state of phone to do avg heat */}
            </div>
          </div>
          <div className="ff-chart-column">
            <div className="ff-chart">
              <img className="ff-placeholder-chart" alt="rhr plot" src={charts[currentChartTypeIndex][0]}></img>
            </div>
            <div className="ff-chart">
              <img className="ff-placeholder-chart" alt="rhr plot" src={charts[currentChartTypeIndex][1]}></img>
            </div>
          </div>
          <div className="ff-chart-column">
            <div className="ff-chart">
              <img className="ff-placeholder-chart" alt="rhr plot" src={charts[currentChartTypeIndex][2]}></img>
            </div>
            <div className="ff-chart">
              <img className="ff-placeholder-chart" alt="rhr plot" src={charts[currentChartTypeIndex][3]}></img>
            </div>
          </div>
        </div>
        <div className="ff-header-container">
          <div className="ff-recent-sessions-title-container">
            <div className="ff-recent-sessions-title">Recent Sessions</div>
            <div className="ff-recent-sessions-subtitle">past 30 days</div>
          </div>
          <div className="ff-slider-container">
            <img
              className="ff-arrow-icon"
              alt="left arrow"
              src={leftarrow}
              onClick={() => {
                handleSessionChange(-1);
              }}
            ></img>
            <div className="ff-slider-text-session">{new Date(currentSession["datetime"]).toLocaleString("en-US", options)}</div>
            <img
              className="ff-arrow-icon"
              alt="right arrow"
              src={rightarrow}
              onClick={() => {
                handleSessionChange(1);
              }}
            ></img>
          </div>
        </div>
        {Object.keys(currentSession).length > 0 && gpsData["lats"] && odometryData && (
          <div className="ff-session-container">
            <div className="ff-session-column">
              <div className="ff-session-plot-container">
                <GPSPlot title={"GPS"} firefighterPlot={true} xData={gpsData["x"]} yData={gpsData["y"]} latData={gpsData["lats"]} longData={gpsData["longs"]} plotID={`gps`} />
              </div>
              <img className="ff-placeholder-chart-session" alt="hr plot" src={hrSessionPlot}></img>
            </div>
            <div className="ff-session-column">
              <div className="ff-session-plot-container">
                <Plot title={"XYZ"} xData={odometryData["x"]} yData={odometryData["y"]} zData={odometryData["z"]} trackingStates={odometryData["trackingStates"]} times={odometryData["times"]} xTitle="x" yTitle="y" zTitle="z" mode="lines+markers" plotID={`odometryXYZ`} />
              </div>
              <img className="ff-placeholder-chart-session" alt="temp plot" src={tempSessionPlot}></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
