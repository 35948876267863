import React, { useState, useRef } from "react";
import Slider from "react-slick";
import Plot from "./plot";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PlotSlider({ odometryData }) {
  const sliderRef = useRef();

  const [currentPlotIndex, setCurrentPlotIndex] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 50,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: false,
    swipeToSlide: false,
    touchMove: false,
    afterChange: (current) => setCurrentPlotIndex(current),
  };

  return (
    <>
      {odometryData && (
        <>
          <Slider ref={sliderRef} {...sliderSettings}>
            {odometryData && odometryData.map((item, index) => <div key={index}>{index === currentPlotIndex && <Plot title={item["uuid"] + " XYZ"} xData={item["x"]} yData={item["y"]} zData={item["z"]} trackingStates={item["trackingStates"]} times={item["times"]} xTitle="x" yTitle="y" zTitle="z" mode="lines+markers" plotID={`odometryXYZ${index}`} />}</div>)}
          </Slider>
        </>
      )}
    </>
  );
}
