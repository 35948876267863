import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import { saveAs } from "file-saver";
import Plot from "../plot/plot";
import SessionVideo from "../sessionVideo/sessionVideo2";
import whiteLogo from "../../img/ascent_logo_white.png";
import "./session.scss";
// import AnimatedPlot from "../plot/animatedPlot"
import downloadIcon from "../../img/download.png";
import backarrow from "../../img/backarrow.png";
// import replayIcon from '../../img/replay.png'
import cameraIcon from "../../img/camera.png";
import gpsIcon from "../../img/gps.png";
import odometryIcon from "../../img/odometry.png";
import pointCloudIcon from "../../img/pointcloud.png";
import deviceIcon from "../../img/device.png";
import iphoneIcon from "../../img/iphone.png";
import arrowIcon from "../../img/longarrow.png";
import ffProfileIcon from "../../img/ffprofile.png";
import GPSPlot from "../plot/gpsPlot";
import PlotSlider from "../plot/plotSlider";
import PointCloud from "../pointCloud/pointCloud";
import { SessionContext } from "../../Contexts.js";

export default function Session({ config }) {
  const { sessionData } = useContext(SessionContext);
  const { sessionParam, sessionDatetime } = useParams();
  const [odometryData, setOdometryData] = useState(undefined);
  const [gpsData, setGpsData] = useState(undefined);
  const [tabletGPS, setTabletGPS] = useState(undefined);
  const [pcdFiles, setPcdFiles] = useState({});
  const [currentPCD, setCurrentPCD] = useState("");
  const [pointCloudLoading, setPointCloudLoading] = useState(false);
  const pointCloudLoadingRef = useRef(false);
  const [noLoadCount, setNoLoadCount] = useState(0);
  const [paths, setPaths] = useState({});
  const [firefighterNames, setFirefighterNames] = useState({});
  // const [ekfData, setEkfData] = useState([]);
  const [thermalStateData, setThermalStateData] = useState(undefined);
  const [lastUpdateData, setLastUpdateData] = useState(undefined);
  const [trackingStateData, setTrackingStateData] = useState(undefined);
  const [batteryStateData, setBatteryStateData] = useState(undefined);
  const [selectedData, setSelectedData] = useState({
    video: false,
    odometry: false,
    gps: false,
    pointCloud: false,
    debug1: false,
  });

  const [dataErrors, setDataErrors] = useState({
    video: false,
    odometry: false,
    gps: false,
    pointCloud: false,
    debug1: false,
    session: false,
  });

  const filterOdometry = (arr) => {
    console.log(arr);
    let filteredArr = arr.filter((obj) => !obj.x.every((element) => element === 0));
    return filteredArr.length ? filteredArr : [];
  };

  useEffect(() => {
    if (sessionData === "error") {
      setDataErrors({
        ...dataErrors,
        session: true,
      });
    } else if (sessionData.firefighterNames && sessionData.devices) {
      setFirefighterNames(sessionData.firefighterNames);
      setCurrentPCD(sessionData["devices"][0]["firefighter"]);
    }
  }, [sessionData]);

  useEffect(() => {
    setDataErrors({
      ...dataErrors,
      odometry: false,
    });
    if (selectedData.odometry && !odometryData) {
      Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/analytics/odometry/${sessionParam}`, config)
        .then((response) => {
          setOdometryData(filterOdometry(response.data.odometry));
        })
        .catch((error) => {
          console.log("Odometry Error", error);
          setDataErrors({
            ...dataErrors,
            odometry: false,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odometryData, selectedData, sessionParam]);

  useEffect(() => {
    setDataErrors({
      ...dataErrors,
      gps: false,
    });
    if (selectedData.gps && !gpsData) {
      Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/analytics/gps/${sessionParam}`, config)
        .then((response) => {
          setGpsData(response.data.gps);
          setTabletGPS(response.data.tablet_gps);
        })
        .catch((error) => {
          console.log("GPS Error", error);
          setGpsData([]);
          setDataErrors({
            ...dataErrors,
            gps: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsData, selectedData, sessionParam]);

  useEffect(() => {
    setDataErrors({
      ...dataErrors,
      pointCloud: false,
    });
    pointCloudLoadingRef.current = true;
    setPointCloudLoading(true);
    if (selectedData.pointCloud && !pcdFiles[currentPCD]) {
      Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/analytics/pointCloud/${sessionParam}/${currentPCD}`, config)
        .then((response) => {
          // Decode the base64 string
          const binary_string = window.atob(response.data.pointCloud);
          const len = binary_string.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }

          // Create a Blob from the binary data
          const blob = new Blob([bytes.buffer], { type: "application/octet-stream" });
          setPcdFiles((prevFiles) => {
            return {
              ...prevFiles,
              [currentPCD]: blob,
            };
          });
          setPaths((prevPaths) => {
            return {
              ...prevPaths,
              [currentPCD]: response.data.path,
            };
          });
          pointCloudLoadingRef.current = false;
          setPointCloudLoading(false);
        })
        .catch((error) => {
          console.log("Point Cloud Error", error);
          setDataErrors({
            ...dataErrors,
            pointCloud: true,
          });
          setPcdFiles((prevFiles) => {
            return {
              ...prevFiles,
              [currentPCD]: undefined,
            };
          });
          pointCloudLoadingRef.current = false;
          setPointCloudLoading(false);
        });
    }
    pointCloudLoadingRef.current = false;
    setPointCloudLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPCD, selectedData, sessionParam]);

  useEffect(() => {
    setDataErrors({
      ...dataErrors,
      debug1: false,
    });
    if (selectedData.debug1) {
      const urls = {
        thermalState: `${process.env.REACT_APP_DB_SERVER_URL}/analytics/thermal_state/${sessionParam}`,
        lastUpdate: `${process.env.REACT_APP_DB_SERVER_URL}/analytics/last_update/${sessionParam}`,
        trackingState: `${process.env.REACT_APP_DB_SERVER_URL}/analytics/tracking_state/${sessionParam}`,
        batteryState: `${process.env.REACT_APP_DB_SERVER_URL}/analytics/battery_state/${sessionParam}`,
      };
      const fetchThermalState = !thermalStateData ? Axios.get(urls.thermalState, config).then((response) => ({ type: "thermalState", data: response.data.thermalStates })) : Promise.resolve();
      const fetchLastUpdate = !lastUpdateData ? Axios.get(urls.lastUpdate, config).then((response) => ({ type: "lastUpdate", data: response.data.lastUpdates })) : Promise.resolve();
      const fetchTrackingState = !trackingStateData ? Axios.get(urls.trackingState, config).then((response) => ({ type: "trackingState", data: response.data.trackingStates })) : Promise.resolve();
      const fetchBatteryState = !batteryStateData ? Axios.get(urls.batteryState, config).then((response) => ({ type: "batteryState", data: response.data.batteryStates })) : Promise.resolve();

      Promise.all([fetchThermalState, fetchLastUpdate, fetchTrackingState, fetchBatteryState])
        .then((results) => {
          results.forEach((result) => {
            if (!result) return;
            switch (result.type) {
              case "thermalState":
                setThermalStateData(result.data);
                break;
              case "lastUpdate":
                setLastUpdateData(result.data);
                break;
              case "trackingState":
                setTrackingStateData(result.data);
                break;
              case "batteryState":
                setBatteryStateData(result.data);
                break;
              default:
                break;
            }
          });
        })
        .catch((error) => {
          console.log("Debug1 - API Error", error);
          setDataErrors({
            ...dataErrors,
            debug1: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thermalStateData, lastUpdateData, trackingStateData, batteryStateData, selectedData, sessionParam]);

  function formatSeconds(seconds) {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  // generate a random hex color
  function randomHexColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // handle toggle change
  const handleToggleChange = (e) => {
    setSelectedData({
      ...selectedData,
      [e.target.name]: e.target.checked,
    });
  };

  // handle download
  function handleDownload() {
    Axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/session/fetch/${sessionParam}`, config).then((response) => {
      let filename = `${sessionParam}.json`;

      let res = response.data.sessionData;

      res.firefighters = firefighterNames;

      let jsonStr = JSON.stringify(res, null, 2);

      const blob = new Blob([jsonStr], {
        type: "application/json;charset=utf-8;",
      });

      if (Object.keys(sessionData).length !== 0) {
        saveAs(blob, filename);
      }

      if (Object.keys(pcdFiles).length > 0) {
        saveAs(pcdFiles[currentPCD], `${sessionParam}_${currentPCD}.pcd`);
      }
    });
  }

  return (
    <div>
      <div className="session-container">
        <img src={whiteLogo} alt="Ascent logo" className="white-logo"></img>
        <div className="session-title-container">
          <Link to="/sessions" className="session-back-container">
            <img src={backarrow} alt="Back arrow icon" className="session-back-icon"></img>
            <div className="session-back-text">Back</div>
          </Link>
          <div className="session-title">{sessionDatetime}</div>
        </div>
        {sessionData.devices && (
          <>
            <div className="session-toggle-data-container">
              <div className="toggles-container">
                <div className="overview-container">
                  <div className="overview-title">Overview</div>
                </div>
                <div className="overview-container">
                  <div className="overview-label"># Devices:</div>
                  <div className="overview-data">{sessionData.devices.length}</div>
                </div>
                <div className="overview-container">
                  <div className="overview-label">Address:</div>
                  <div className="overview-data">{sessionData.address}</div>
                </div>
                <div className="overview-container">
                  <div className="overview-label">Length:</div>
                  <div className="overview-data">{formatSeconds(sessionData.session_length)}</div>
                </div>
              </div>
              <div className="active-units-container">
                <div className="active-units-title">Active Units</div>
                {sessionData.devices.map((device, index) => {
                  return (
                    <div key={index} className="active-unit">
                      <div className="active-unit-data">{device.device_name ? device.device_name : device.device_id}</div>
                      <img className="iphone-icon" src={iphoneIcon} alt="device"></img>
                      <img className="long-arrow-icon" src={arrowIcon} alt="arrow"></img>
                      <div className="ff-profile-icon-container" style={{ backgroundColor: randomHexColor() }}>
                        <img className="ff-profile-icon" src={ffProfileIcon} alt="ff proflie"></img>
                      </div>
                      <div className="active-unit-data">{firefighterNames[device.firefighter]}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div className="session-toggle-data-container">
          {!dataErrors.session && sessionData.devices && (
            <div className="toggles-container">
              <label className="toggle-container">
                <div className="toggle-icon-container">
                  <img src={cameraIcon} alt="Replay icon" className="camera-icon"></img>
                  <div>Video</div>
                </div>
                <input
                  type="checkbox"
                  name="video"
                  checked={selectedData.video}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setNoLoadCount(0);
                    }
                    handleToggleChange(e);
                  }}
                />
                <div className="toggle"></div>
              </label>
              <label className="toggle-container">
                <div className="toggle-icon-container">
                  <img src={gpsIcon} alt="GPS icon" className="replay-icon"></img>
                  <div>GPS</div>
                </div>
                <input type="checkbox" name="gps" checked={selectedData.gps} onChange={handleToggleChange} />
                <div className="toggle"></div>
              </label>
              {currentPCD && (
                <label className="toggle-container">
                  <div className="toggle-icon-container">
                    <img src={pointCloudIcon} alt="Point cloud icon" className="replay-icon"></img>
                    <div>Point Cloud</div>
                  </div>
                  <input type="checkbox" name="pointCloud" checked={selectedData.pointCloud} onChange={handleToggleChange} />
                  <div className="toggle"></div>
                </label>
              )}
              <label className="toggle-container">
                <div className="toggle-icon-container">
                  <img src={odometryIcon} alt="Odometry icon" className="replay-icon"></img>
                  <div>Odometry</div>
                </div>
                <input type="checkbox" name="odometry" checked={selectedData.odometry} onChange={handleToggleChange} />
                <div className="toggle"></div>
              </label>
              <label className="toggle-container">
                <div className="toggle-icon-container">
                  <img src={deviceIcon} alt="Device status icon" className="replay-icon"></img>
                  <div>Device Status</div>
                </div>
                <input type="checkbox" name="debug1" checked={selectedData.debug1} onChange={handleToggleChange} />
                <div className="toggle"></div>
              </label>
              <div className="download-container">
                <div className="download-inner-container" onClick={() => handleDownload()}>
                  <img src={downloadIcon} alt="Download icon" className="download"></img>
                  <div className="download-data-text">Download Data</div>
                </div>
              </div>
            </div>
          )}
          <div className={`session-data-container ${Object.values(selectedData).every((val) => val === false) ? "session-data-container-no-data" : ""}`}>
            {
              <div>
                {selectedData.video && sessionData.devices && (
                  <>
                    <div className="plot-title">Session Video</div>
                    <div>
                      {sessionData.devices.map((device, index) => {
                        // return <SessionVideo key={device.device_id} session={sessionParam} device={device.device_name ? device.device_name : device.device_id} setNoLoadCount={setNoLoadCount}/>;
                        return <SessionVideo key={device.device_id} index={index} />;
                      })}
                    </div>
                    {noLoadCount === sessionData.devices.length && <div className="plot-data-error-text">No video found for session.</div>}
                  </>
                )}

                {selectedData.gps && (
                  <div className="plot-container">
                    <div className="plot-title gps-plot-title">
                      <div>Displayed Path</div>
                    </div>
                    {!gpsData && !dataErrors.gps && (
                      <div className="plot-loading-container">
                        <i alt="Loading GPS data" className="fa-solid fa-rotate fa-spin point-cloud-load-icon"></i>
                      </div>
                    )}
                    {dataErrors.gps && <div className="plot-data-error-text">An error has occurred, please try again later.</div>}
                    {gpsData && gpsData.length > 0 && (
                      <div className="plot-chart-container">
                        <GPSPlot key={0} index={0} gpsData={gpsData} plotID="gps" tabletGPS={tabletGPS} />
                        {gpsData.map(
                          (item, index) =>
                            item["calibrated_lats"].length > 0 && (
                              <div className="plot-chart-inner-container" key={index++} index={index++}>
                                <GPSPlot index={index++} key={index++} title={`${item["uuid"]}`} calibratedLats={item["calibrated_lats"]} calibratedLongs={item["calibrated_longs"]} latData={item["lats"]} longData={item["longs"]} uncertainty={item["uncertainty"]} plotID={`gps${index}`} />
                              </div>
                            )
                        )}
                      </div>
                    )}
                  </div>
                )}
                {selectedData.pointCloud && (
                  <div className="plot-container">
                    <div className="plot-title">
                      <div>Point Cloud</div>
                    </div>
                    {!dataErrors.pointCloud && pointCloudLoading && (
                      <div className="plot-loading-container">
                        <i alt="Loading Point Cloud data" className="fa-solid fa-rotate fa-spin point-cloud-load-icon"></i>
                      </div>
                    )}
                    <div className="plot-chart-container">
                      {!dataErrors.pointCloud && !pointCloudLoading && (
                        <div>
                          <div>{firefighterNames[currentPCD]}</div>
                          <select
                            className="point-cloud-dropdown"
                            value={currentPCD}
                            onChange={(e) => {
                              pointCloudLoadingRef.current = true;
                              setPointCloudLoading(true);
                              setCurrentPCD(e.target.value);
                            }}
                          >
                            {Object.entries(firefighterNames).map((item, index) => (
                              <option className="point-cloud-option" key={index} value={item[0]}>
                                {item[1]}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {!dataErrors.pointCloud && !pointCloudLoadingRef.current && <PointCloud pcdFile={pcdFiles[currentPCD]} path={paths[currentPCD]} />}
                      {dataErrors.pointCloud && <div className="session-data-error"> No point cloud exists for this session.</div>}
                    </div>
                  </div>
                )}
                {selectedData.odometry && (
                  <div className="plot-container">
                    <div className="plot-title-odometry plot-title">
                      <div>Odometry</div>
                    </div>
                    {!odometryData && (
                      <div className="plot-loading-container">
                        <i alt="Loading Odometry data" className="fa-solid fa-rotate fa-spin point-cloud-load-icon"></i>
                      </div>
                    )}
                    {odometryData && odometryData.length === 1 && (
                      <div className="plot-chart-container ">
                        {odometryData.map((item, index) => (
                          <div key={index} className="odometry-chart-inner-container">
                            <Plot key={index} title={item["uuid"] + " XYZ"} xData={item["x"]} yData={item["y"]} zData={item["z"]} trackingStates={item["trackingStates"]} times={item["times"]} xTitle="x" yTitle="y" zTitle="z" mode="lines+markers" plotID={`odometryXYZ${index}`} />
                          </div>
                        ))}
                      </div>
                    )}
                    {odometryData && odometryData.length > 1 && (
                      <div className="plot-chart-container ">
                        <div className="odometry-chart-inner-container">
                          <PlotSlider odometryData={odometryData.filter((ff) => ff.x.length)} />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {selectedData.debug1 && (
                  <div className="plot-container">
                    <div className="plot-title plot-title-debug">Device Status</div>
                    {(!thermalStateData || !lastUpdateData || !trackingStateData || !batteryStateData) && !dataErrors.debug1 ? (
                      <div className="plot-loading-container">
                        <i alt="Loading device status data" className="fa-solid fa-rotate fa-spin point-cloud-load-icon"></i>
                      </div>
                    ) : null}
                    {dataErrors.debug1 ? <div className="plot-data-error-text">An error has occurred, please try again later.</div> : null}
                    {thermalStateData && lastUpdateData && trackingStateData && batteryStateData && (
                      <div className="plot-chart-container debug-plot-container">
                        {thermalStateData.map((item, index) => (
                          <div className="debug-chart" key={index}>
                            <Plot title={item["uuid"]} times={item["times"]} yData1={item["thermalStates"]} yData2={lastUpdateData[index]["lastUpdates"]} yData3={trackingStateData[index]["trackingStates"]} yData4={batteryStateData[index]["batteryStates"]} xTitle="Time" yTitle1="Thermal Status" yTitle2="Secs. Since Last Refresh" yTitle3="Tracking Status" yTitle4="Battery Status" mode="lines" plotID={`debug1${index}`} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
            {Object.values(selectedData).every((val) => val === false) && !dataErrors.session ? <div className="no-data">Select a data set to analayze.</div> : null}
            {dataErrors.session ? <div className="no-data">Something went wrong, please try again later.</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
